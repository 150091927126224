import { Component, OnInit } from '@angular/core';
import { CustomerAcquisitionService } from '../../services/customer_acquisition.service';
import { ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup } from '@angular/forms';

import { ModalViewCustomerComponent } from '../../../shared/components/modal_edit_customer/modal_edit_customer.component';
import { ModalCreateRemindComponent } from '../../../shared/components/modal_edit_remind/modal_create_remind.component';

import { GlobalConstants } from 'src/app/shared/constants/global-constants';

declare var $: any; //JQUERY

@Component({
  selector: 'app-allcustomers',
  templateUrl: './customers_acquisition.component.html',
  styleUrls: ['./customers_acquisition.component.less']
})

export class AllCustomersComponent implements OnInit {

  subtitle_str: string = "";

  newForm: FormGroup;
  FormTitleString: string;
  input_form_disabled: boolean;
  fecha_create;
  fecha_edit;
  customer : any = {"idempresa":0,"nombre":"","categoria":"","idcomercial":0,"date_remind":"","hora":"","comentario":"","destacado":0};
  isCollapsed;

  createRemindForm: FormGroup;

  remind: any = [];
  reminders: any = [];

  idcommercial: number = 0; //Recibido en la URL

  user_type:string = GlobalConstants.user_type;
  user_id:string = GlobalConstants.user_id;

  nombre_comercial: string;
  email: string;

  customers_array: any = [];

  status_array_str = GlobalConstants.status_array_str;
  destacado_array_str = GlobalConstants.destacado_array_str;
  procedencia_array_str = GlobalConstants.procedencia_array_str;
  comercials_array_str = GlobalConstants.comercials_array_str;
  sectors_array_str = GlobalConstants.sectors_array_str;
  
  key: string = 'nombre';
  reverse: boolean = false;
  sort(key){
    this.key = key;
    this.reverse = !this.reverse;
  }
  p: number = 1;

  ShowLinkCol: boolean = false;
  ShowCommentCol: boolean = false;
  ShowAddressCol: boolean = false;

  filter:number;
  customers_array_filtered:any = [];

  searchText: String = '';
  filterMetadata = { 
    count: 0,
    filters: ["idempresa","nombre","direccion","city","province","cp","pais","status_str","comercial_str","destacado_str","nom_sector","idprocedencia_str","fecha_edit","link","nota"]
   };

  constructor(private customerAcquisitionService: CustomerAcquisitionService,private route:ActivatedRoute, private viewcustomerService:ModalViewCustomerComponent, private createremindService:ModalCreateRemindComponent, private formBuilder: FormBuilder) { 
    //Conectamos con los formularios de otros componentes
    this.newForm = viewcustomerService.newForm;
    this.createRemindForm = createremindService.createRemindForm;
  }

  ngOnInit() {

    this.idcommercial=this.route.snapshot.params.idcomercial;
    this.nombre_comercial=this.route.snapshot.params.nombre_comercial;
    this.email=localStorage.getItem('email');

    this.filter=this.route.snapshot.params.filter;

    this.customerAcquisitionService.get_customers_acquisition({idcomercial : this.idcommercial, filter: this.filter }).subscribe(
      data =>{
        this.init_table_customers(data);

        if(this.route.snapshot.params.idempresa){ //Abrimos directamente un cliente por ID

          const index = this.customers_array.findIndex(element => element.idempresa === this.route.snapshot.params.idempresa);
          const item = this.customers_array[index];

          this.show_modal_view(item);
          $('#viewcustomerModal').modal('show');
        }

        if( this.user_type.indexOf('gerente') > -1){
          this.subtitle_str = " | Ordendos por Actividad";
          this.key = 'fecha_edit';
          this.ShowCommentCol = true;
          this.reverse = true;
        }
      }
    );
    this.customerAcquisitionService.get_reminds(this.idcommercial).subscribe(
      data =>{
        this.reminders=data;
      } 
    );
  }

  init_table_customers(data){

    if(data == "logout") window.location.href = '/#/login';
    else{

      this.customers_array= data;

      for (let i in this.customers_array){
        this.customers_array[i].index = i;
        if(this.customers_array[i].nombre == null) this.customers_array[i].nombre = ''; //Fix problem order table by nombre
        this.customers_array[i].idprocedencia_str = this.procedencia_array_str[ this.customers_array[i].idprocedencia ].value;
        this.customers_array[i].status_str = this.status_array_str[ this.customers_array[i].status ].value;
        this.customers_array[i].destacado_str = this.destacado_array_str[ this.customers_array[i].destacado ].value;
        if(this.customers_array[i].sector != null) this.customers_array[i].sector_str = this.sectors_array_str[ this.customers_array[i].sector ].value;
        else this.customers_array[i].sector_str = '';

        //this.customers_array[i].comercial_str = GlobalConstants.comercials_array_str[ GlobalConstants.comercials_array_str.findIndex(element => element.index === this.customers_array[i].idcomercial) ].value; //Para mostrar el comercial en la tabla de empresas similares del modal. TODO Ralentiza mucho
      }

      this.customers_array_filtered =  this.filterArray( this.customers_array );
    }
  }
  
  print(){
    window.print();
  }

  show_modal_new(){

    this.FormTitleString = "New ";
    this.fecha_create = this.fecha_edit = '';

    let item = {idempresa: "0"};
    this.customer = item;

    this.input_form_disabled = null;
    this.viewcustomerService.show_modal(item);
  }

  show_modal_view(item){

    this.customer = item;

    this.FormTitleString = "View ";
    this.fecha_create =  item.fecha_create;
    this.fecha_edit = item.fecha_edit;

    this.input_form_disabled = true;
    this.viewcustomerService.show_modal(item);
  }
  
  show_modal_edit(item){

    this.customer = item;

    this.FormTitleString = "Edit ";
    this.fecha_create =  item.fecha_create;
    this.fecha_edit = item.fecha_edit;

    this.input_form_disabled = null;
    this.viewcustomerService.show_modal(item);
  }

  show_modal_create_remind(item){

    this.FormTitleString = "Edit ";
    this.email = this.email;

    this.input_form_disabled = null;
    this.remind = { "idempresa":item.idempresa , "nombre":item.nombre, "idcomercial":this.user_id};
    this.createremindService.show_modal( this.remind );
  }

  filterArray(arraySearch){

    let arrayResults = [];

      arrayResults = arraySearch.filter( obj => {
          
          return this.idcommercial == null ?  true : obj.idcomercial == this.idcommercial;
      });

    return arrayResults;
  }
}
