import { Pipe, PipeTransform } from '@angular/core';
import { isArray } from 'util';

@Pipe({
    name: 'highlight'
})

export class HighlightSearch implements PipeTransform {

    transform(value: any, args: any): any {
        if (!args || value==null || value=='') {return value;}

        var newText = value;

        if( isArray(args) ){ //Si queremos buscar varios recibimos en el segundo parametro un Array:
            for(var i=0; i < args.length; i++ ){
                
                newText = this.Highlight(newText, args[i]);
            }
        }
        else{
            newText = this.Highlight(newText, args);
        } 

        return newText;
    }

    Highlight(newText, args) {

        if(args!=null && args !='' && newText!=null && newText!=''){

            newText = newText.toString(); //Transformamos a String

            var re = new RegExp(args, 'gi'); //'gi' for case insensitive and can use 'g' if you want the search to be case sensitive.
            return newText.replace(re, "<mark>$&</mark>");
            
        }else return newText;
    }
}

