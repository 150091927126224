import { NgModule } from '@angular/core';
import { Routes, RouterModule} from '@angular/router';
import { NavbarComponent } from './core/components/navbar/navbar.component';
import { LoginComponent } from './core/pages/login/login.component';
import { AnuariosComponent } from './core/pages/anuarios/anuarios.component';

import { CountersComponent } from './core/pages/all_comercials/counters.component'
import { AllCustomersComponent } from './core/pages/customers_acquisition/customers_acquisition.component'
import { AllRemindsComponent } from './core/pages/all_reminds/all_reminds.component'

import { NewSeguimientoComponent } from './core/pages/new_seguimiento/newseguimiento.component';
import { GetSeguimientosComponent } from './core/pages/get_seguimientos/getseguimientos.component';

import { AllAnswersComponent } from './core/pages/all_answers/allanswers.component';
import { StatisticsComponent } from './core/pages/statistics/statistics.component';

import { PPricesComponent } from './core/pages/pprices/pprices.component';
import { OrdersComponent } from './core/pages/orders/orders.component';

import { AllFinalCustomersComponent } from './core/pages/all_final_customers/allfinalcustomers.component';

import { CartridgePriceComponent } from './core/pages/cartridge_price/cartridge_price.component';

const routes: Routes = [
    {path: '', redirectTo: 'login', pathMatch: 'full'},
    {path: 'login', component: LoginComponent},
    {path: 'navbar', component: NavbarComponent},
    {path: 'anuarios', component: AnuariosComponent},

    {path: 'counters', component: CountersComponent},
    {path: 'customersacquisitions/:idcomercial/:nombre_comercial', component: AllCustomersComponent},
    {path: 'customersacquisitions/:idempresa', component: AllCustomersComponent},
    {path: 'customersacquisitions', component: AllCustomersComponent},
    {path: 'reminds', component: AllRemindsComponent},
    {path: 'reminds/:idcomercial/:nombre_comercial', component: AllRemindsComponent},

    {path: 'newseguimiento/:id_empresa/:empresa/:type', component: NewSeguimientoComponent},
    {path: 'allseguimientos', component: GetSeguimientosComponent},
    {path: 'allseguimientos/:idcomercial/:nombre_comercial', component: GetSeguimientosComponent},
    {path: 'allseguimientos/:id_empresa/:empresa/:type', component: GetSeguimientosComponent},

    {path: 'allanswers', component: AllAnswersComponent},
    {path: 'allanswers/:website/:id_answer', component: AllAnswersComponent},
    {path: 'statistics', component: StatisticsComponent},

    {path: 'pprices', component: PPricesComponent},
    {path: 'orders', component: OrdersComponent},
    {path: 'orders/:website/:id', component: OrdersComponent},

    {path: 'allcustomers', component: AllFinalCustomersComponent},

    {path: 'cartridgeprice', component: CartridgePriceComponent}
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports:[RouterModule]
})

export class AppRoutingModule{

}
