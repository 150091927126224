import { Component, OnInit } from '@angular/core';
import { SeguimientoService } from '../../services/seguimiento.service';
import { FilterPipe } from 'ngx-filter-pipe';

import { ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators, NgForm } from '@angular/forms';

import { GlobalConstants } from '../../../shared/constants/global-constants';

@Component({
  selector: 'app-all',
  templateUrl: './getseguimientos.component.html',
  styleUrls: ['./getseguimientos.component.less']
})

export class GetSeguimientosComponent implements OnInit {

  private sub: any;

  newForm: FormGroup;
  input_form_disabled = true;

  offer_state_array_str = GlobalConstants.offer_state_array_str;
  type_array_str = GlobalConstants.seguimiento_type_array_str;

  seguimientos_array : any = [];
  show:boolean = false;
  response;

  id_empresa: number;
  id;
  oferta;
  empresa;
  state;
  observacion;
  units;
  index;
  type;

  idcommercial: number = 0;
  user_type:string = GlobalConstants.user_type;
  user_id:string = GlobalConstants.user_id;
  
  nombre_empresa: string = "";
  title_str : string = "";

  key: string = 'fecha';
  reverse: boolean = false;
  sort(key){
    this.key = key;
    this.reverse = !this.reverse;
  }

  searchText: String = '';
  filterMetadata = { 
    count: 0,
    filters: ["empresa", "oferta", "idcomercial_str", "date_updated", "observacion", "units", "state_array_str", "type_str"]
   };

  constructor(private seguimientosservice: SeguimientoService, private filter: FilterPipe, private route: ActivatedRoute, private formBuilder: FormBuilder) {  
    this.newForm = formBuilder.group({
      'oferta': ['', [Validators.required, Validators.maxLength(79) ]],
      'state': ['', Validators.maxLength(79)],
      'observacion': ['', Validators.maxLength(79)],
      'units': ['', [Validators.required, Validators.pattern("^[0-9]*$"), Validators.minLength(1) ]],
    });  
  }

  ngOnInit() {

    this.sub = this.route.params.subscribe(params => {

      this.id_empresa = +params['id_empresa']; // (+) converts string 'id' to a number
      this.type = +params['type'];
      this.idcommercial = +params['idcomercial'];
      
      if(this.id_empresa){
        this.nombre_empresa = params['empresa'];
        this.title_str = this.nombre_empresa;
      }
      else this.title_str = " Todas Las Empresas";

      if(this.idcommercial) this.title_str +=  " de " + params['nombre_comercial'];

      // In a real app: dispatch action to load the details here.

      this.get_all();
    });
  }

  get_all(){

    const filter = {
      id_empresa: this.id_empresa,
      id_user: this.idcommercial
    };

    this.seguimientosservice.get_seguimientos( filter ).subscribe(
      data => {
        this.fill_table(data, filter);
      }
    );    
  }

  fill_table(data, filter){

    for (let i in data){
       data[i].state_array_str = this.offer_state_array_str[ data[i].state ].value;
       data[i].units = parseInt( data[i].units);
       data[i].type_str = this.type_array_str[ +data[i].type ].value;
       data[i].index = i;
    }

    this.seguimientos_array = data;//this.filter_table(this.seguimientos_array, filter);
  }

  /*filter_table(arraySearch, filter){ 
      
    let arrayResults = [];

    if(filter.id_user != null){ console.log("Entra", filter.id_user);

      arrayResults = arraySearch.filter( obj => {
        return obj.id_comercial != this.idcomercial;
      });

    }

    return arrayResults;
  }*/

  show_modal_del_item(item){
    this.nombre_empresa= item.empresa;
    this.id= item.id;
    this.oferta= item.oferta;
  }

  show_modal_edit_item(item, input_form_disable){
    this.state= item.state;
    this.nombre_empresa= item.empresa;
    this.id= item.id;
    this.oferta= item.oferta;
    this.observacion= item.observacion;
    this.index= item.index;
    this.units= item.units;

    this.input_form_disabled = input_form_disable;
  }

  del_item(id){

    this.seguimientosservice.del_item(id).subscribe(
      result => {
        
        if(result['resultado'] == 'OK') {
          this.response=result['mensaje'];
          this.show_message();

          document.getElementById("Modal").style.display = "none";
          var modal;
          modal as HTMLCollectionOf<HTMLElement>;
          modal = document.getElementsByClassName("modal-backdrop")[0];
          modal.style.display = "none";

          this.get_all();
      }
    });
  }

  submit(form: NgForm){

    form.value.id = this.id;
    form.value.type= this.type;

    if(form.dirty && form.valid){

      this.seguimientosservice.set_seguimiento(form.value).subscribe(
        result => {
          if(result['resultado'] == 'OK') {

            this.response=result['mensaje'];
            this.show_message();

            this.seguimientos_array[this.index].state = this.state;
            this.seguimientos_array[this.index].state_array_str = this.offer_state_array_str[ this.state ].value;
            this.seguimientos_array[this.index].observacion = this.observacion;
            this.seguimientos_array[this.index].oferta = this.oferta;
            this.seguimientos_array[this.index].units = this.units;
        }
      });
      //f.resetForm();
    }
  }

  show_message(){

    this.show = !this.show;

    setTimeout(() => {
      this.hides_message();
    }, 2000);
  }

  hides_message(){
    if(this.show=false){
      this.show;
    }
  }
}
