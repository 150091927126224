import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppComponent } from './app.component';
import { LoginComponent } from './core/pages/login/login.component';
import { NavbarComponent } from './core/components/navbar/navbar.component';
import { AppRoutingModule } from './app.routing.module';
import { AnuariosComponent } from './core/pages/anuarios/anuarios.component';

import { HttpClientModule } from '@angular/common/http';
import { LocationStrategy, HashLocationStrategy} from '@angular/common';
import { CountersComponent } from './core/pages/all_comercials/counters.component';
import { AllCustomersComponent } from './core/pages/customers_acquisition/customers_acquisition.component';
import { AllRemindsComponent } from './core/pages/all_reminds/all_reminds.component';
import { OrderModule } from 'ngx-order-pipe';
import { NgxPaginationModule} from 'ngx-pagination';
import { UserService } from './core/services/user.service';
import { FilterPipeModule } from 'ngx-filter-pipe';

import { NewSeguimientoComponent } from './core/pages/new_seguimiento/newseguimiento.component';
import { GetSeguimientosComponent } from './core/pages/get_seguimientos/getseguimientos.component';

import { AllAnswersComponent } from './core/pages/all_answers/allanswers.component';
import { StatisticsComponent } from './core/pages/statistics/statistics.component';

import { PPricesComponent } from './core/pages/pprices/pprices.component';
import { OrdersComponent } from './core/pages/orders/orders.component';

import { AllFinalCustomersComponent } from './core/pages/all_final_customers/allfinalcustomers.component';

import { SearchFilterPipe } from './shared/pipes/search-filter.pipe';
import { HighlightSearch } from './shared/pipes/search-highlight.pipe';
import { ModalViewCustomerComponent } from './shared/components/modal_edit_customer/modal_edit_customer.component';
import { ModalCreateRemindComponent } from './shared/components/modal_edit_remind/modal_create_remind.component';

import { CartridgePriceComponent } from './core/pages/cartridge_price/cartridge_price.component';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    NavbarComponent,
    AnuariosComponent,
    CountersComponent,
    AllCustomersComponent,
    AllRemindsComponent,
    NewSeguimientoComponent,
    GetSeguimientosComponent,
    AllAnswersComponent,
    StatisticsComponent,
    PPricesComponent,
    AllFinalCustomersComponent,
    OrdersComponent,

    SearchFilterPipe,
    HighlightSearch,
    ModalViewCustomerComponent,
    ModalCreateRemindComponent,

    CartridgePriceComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    OrderModule,
    NgxPaginationModule,
    FilterPipeModule,
  ],
  providers: [{provide: LocationStrategy, useClass: HashLocationStrategy,},UserService],
  bootstrap: [AppComponent]
})
export class AppModule { }
