import { Component, OnInit, ViewChild } from '@angular/core';
import { AnswerService } from '../../services/answer.services';

import { ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators,NgForm, FormControl } from '@angular/forms';
import { GlobalConstants } from 'src/app/shared/constants/global-constants';

import { CustomerAcquisitionService } from '../../services/customer_acquisition.service';

declare var $: any; //JQUERY

@Component({
  selector: 'app-all',
  templateUrl: './allanswers.component.html',
  styleUrls: ['./allanswers.component.less']
})

export class AllAnswersComponent implements OnInit{

  private sub: any;

  newForm: FormGroup;
  searchForm: FormGroup;

  id_user;

  answers: any = [];
  answers_array_filtered: any = [];
  alertshow:boolean = false;
  response:string;

  index:number;

  id:number;
  idpedido:number;
  website:string;
  respuesta1:string;
  respuesta2:string;
  idusuario:number;
  descuento:string;
  date_updated:string;
  comments:string;
  fecha:string;

  key: string = 'fecha';
  reverse: boolean = true;
  sort(key){
    this.key = key;
    this.reverse = !this.reverse;
  }

  input_form_disabled; //null or true

  customerAnswers_array: any = [];
  answersSimilars_array: any = [];
  searchTextSimilars: any = [];
  searchTextSimilars_withoutIgnore: any = [];

  state_array_str = [
    {'index':'0','value':''},
    {'index':'1','value':'✓'},
    {'index':'2','value':'X'}
  ];

  ignoreSearchText = ["heating","de","-","machine","machinery","tool","/","https","http","www","respuesta","incorrecta","contactar","cliente","contactado","el","descuento","anterioridad"];
  searchText: String = '';
  filterMetadata = { count: 0 };
  searchText2: String = '';
  filterMetadata2 = { count: 0 };
  filterMetadata3 = { count: 0 };
  filterMetadata4 = { count: 0 };

  websites_array_str = GlobalConstants.websites_array_str;
  discounts_array_str = GlobalConstants.discounts_array_str;
  comercials_array_str = GlobalConstants.comercials_array_str;
  users_array_str = GlobalConstants.users_array_str;
  status_array_str = GlobalConstants.status_array_str;
  destacado_array_str = GlobalConstants.destacado_array_str;
  procedencia_array_str = GlobalConstants.procedencia_array_str;

  customers_array: any = [];
  customers_array_filtered: any = [];

  //@ViewChild('myModal') myModal;

  linkshare: string = '';

  constructor(private answerservice: AnswerService, private route: ActivatedRoute, private formBuilder: FormBuilder, private customerAcquisitionService: CustomerAcquisitionService) {  
    this.newForm = formBuilder.group({
      'respuesta1' : '',
      'respuesta2' : '',
      'website': '',
      'descuento': '',
      'comments': '',
      'state': '',
      'idpedido': '',
      'fecha': ''
    });

    this.searchForm = formBuilder.group({
      date_end :  new FormControl(),
      date_start :  new FormControl(),
    }); 
  }

  ngOnInit() {

    this.id_user = localStorage.getItem('id');

    this.sub = this.route.params.subscribe(params => {
      // In a real app: dispatch action to load the details here.
      this.get_answers();
    });

    this.get_customers();
  }

  get_answers(){
    this.answerservice.get_answers().subscribe(
      /*data => //console.log(data)
      this.answers= data*/

      data => {

        if(data == "logout") window.location.href = '/#/login';
        else this.answers= data;

        for (let i in this.answers){
           this.answers[i].index = i;

           try {
            this.answers[i].respuesta =  JSON.parse(  this.answers[i].respuesta  );
            
            this.answers[i].respuesta1 = this.answers[i].respuesta.res;
            this.answers[i].respuesta2 = this.answers[i].respuesta.apli;

            if(this.answers[i].respuesta.website != null) this.answers[i].website = this.answers[i].respuesta.website;
            
            //if(this.answers[i].state == 1) this.answers[i].state_str = "✓";
            //else this.answers[i].state_str = "";

            if(this.answers[i].state == null) this.answers[i].state = 0;
            this.answers[i].state_str = this.state_array_str[ this.answers[i].state ].value;
            //El ID y el indice no tiene porque coincidir, por lo que hay que buscarlo: TODO Ralentiza mucho
            this.answers[i].comercial_str = this.users_array_str[ this.users_array_str.findIndex(element => element.index === this.answers[i].comercial) ].value;
            
          } catch (e) {

          }           
        }

        this.answers_array_filtered = this.answers;

        this.show_modal_item_url();
      }
    );    
  }

  show_modal_item_url(){

    let website = this.route.snapshot.params.website;
    let id_answer=this.route.snapshot.params.id_answer;

    if(  website != undefined && id_answer != undefined){

      let item = this.answers[ this.answers.findIndex( element => element.id === id_answer && element.website === website ) ];
      this.show_modal_edit_item(item);
      $('#ModalEdit').modal('show');
    } 
  }

  get_customers() {

    this.customerAcquisitionService.get_customers_acquisition(null).subscribe(
      data =>{
        this.init_table_customers(data);
      }
    );
  }

  init_table_customers(data){

      this.customers_array= data;

      for (let i in this.customers_array){
        this.customers_array[i].index = i;
        if(this.customers_array[i].nombre == null) this.customers_array[i].nombre = ''; //Fix problem order table by nombre
        this.customers_array[i].idprocedencia_str = this.procedencia_array_str[ this.customers_array[i].idprocedencia ].value;
        this.customers_array[i].status_str = this.status_array_str[ this.customers_array[i].status ].value;
        this.customers_array[i].destacado_str = this.destacado_array_str[ this.customers_array[i].destacado ].value;
        //this.customers_array[i].comercial_str = GlobalConstants.comercials_array_str[ GlobalConstants.comercials_array_str.findIndex(element => element.index === this.customers_array[i].idcomercial) ].value; //Para mostrar el comercial en la tabla de empresas similares del modal. TODO Ralentiza mucho
      }
  }

  show_modal_edit_item(item, input_form_disabled = true){

    this.id= item.id;
    this.index= item.index;
    this.website= item.website;
    this.respuesta1 = item.respuesta1;
    this.respuesta2= item.respuesta2;
    this.descuento= item.descuento;
    this.date_updated = item.date_updated;
    this.idpedido = item.idpedido;
    this.idusuario = item.idusuario;
    this.comments = item.comments;
    this.fecha = item.fecha;

    this.newForm.patchValue(item);
    this.input_form_disabled = input_form_disabled;

    this.customerAnswers_array = this.filterArray( this.answers );
    this.check_similars();

    this.linkshare = "http://"+document.location.hostname+"/#/allanswers/"+item.website+"/"+item.id;
  }

  submit(form: NgForm){

    form.value.id = this.id;
    form.value.id_comercial = this.id_user;
    if(this.id != 0) form.value.website = this.website;

    if(form.dirty && form.valid){
      this.answerservice.set_answer(form.value).subscribe(
        result => {
          if(result['resultado'] == 'OK') {

            this.response=result['mensaje'];
            this.alert_show();

            if(this.id == 0) this.reset_form();
            else{

              this.answers[this.index].comments = form.value.comments;
              this.answers[this.index].state = form.value.state;
  
              this.answers[this.index].state_str = this.state_array_str[ this.answers[this.index].state ].value;
            }
          }
      });
    }
  }

  alert_show(){
    this.alertshow = true;

    setTimeout(() => {
      this.alert_hide();
    }, 2000);
  }

  alert_hide(){
    this.alertshow = false;
  }

  filterArray(arraySearch){

    let arrayResults = [];

      arrayResults = arraySearch.filter( obj => {

          return  obj.id != this.id && obj.idusuario == this.idusuario;
      });

    return arrayResults;
  }

  check_similars(){

    const item = { "respuesta1": this.respuesta1 , "respuesta2" : this.respuesta2, "comments" : this.comments};

    let arraySearch= this.answers;   
    this.answersSimilars_array = this.check_answers_similars(item, arraySearch);

    arraySearch = this.customers_array;
    this.customers_array_filtered = this.check_customers_similars(item, arraySearch);
  }

  check_answers_similars(item , arraySearch){

    let arrayResults = [];

    let strSearch = item.respuesta1 + ' ' + item.respuesta2;

    const strSearchLink = item.comments; //Tambien buscamos por links
    if(strSearchLink!='')
      strSearch = strSearch+' '+this.clearURL( strSearchLink);

    var words = strSearch.toLowerCase().split(/[\s,\-.\\\/()]+/mg);//split(' '); //Comparamaos por cada palabra separada por Space coma - .
    words = this.uniqsInArray(words);

    this.searchTextSimilars = [];
    Object.assign( this.searchTextSimilars , words); //this.searchTextSimilars = words;

    for(var i=0; i < words.length; i++ ){

      if ( !this.ignoreSearchText.includes(words[i]) ) arrayResults = arrayResults.concat( this.searchStringInArrayObjs_Answers(arraySearch, words[i]) );
      else words[i] = null;
    }

    this.searchTextSimilars_withoutIgnore = words;

    arrayResults = this.uniqsInArray( arrayResults );

    return  arrayResults;
  }

  searchStringInArrayObjs_Answers(arraySearch, strSearch){ 
      
    let arrayResults = [];

    if(strSearch != null && strSearch != "" && strSearch!= "undefined"){

      strSearch = strSearch.toLowerCase();

      arrayResults = arraySearch.filter( obj => {
        return obj.id != this.id  && //Evitamos que se muestre así misma
           ( 
            (  obj.respuesta1 != null && obj.respuesta1.toLowerCase().indexOf( strSearch ) > -1 ) || 
            ( obj.respuesta2 != null && obj.respuesta2.toLowerCase().indexOf( strSearch ) > -1 ) ||
            ( obj.comments != null && obj.comments.toLowerCase().indexOf( strSearch ) > -1 )
           )
           ;
      });

    }

    return arrayResults;
  }

  /**
   * Remove Duplications in Array
   * 
   * @param Array 
   * @returns Array
   */
  uniqsInArray(Array) {
    var prims = {"boolean":{}, "number":{}, "string":{}}, objs = [];

    return Array.filter(function(item) {
        var type = typeof item;
        if(type in prims)
            return prims[type].hasOwnProperty(item) ? false : (prims[type][item] = true);
        else
            return objs.indexOf(item) >= 0 ? false : objs.push(item);
    });
  }



  /**
   * Return Array1 menos Array2
   * @param Array1 
   * @param Array2 
   * @returns 
   */
  /*differenceInArray(Array1, Array2) {

    let arrayResults = [];

    // A comparer used to determine if two entries are equal.
    const isObject = (a, b) => a.id === b.id && a.index === b.index;
    
    // Get items that only occur in the left array,
    // using the compareFunction to determine equality.
    const onlyInLeft = (left, right, compareFunction) => 
      left.filter(leftValue =>
        !right.some(rightValue => 
          compareFunction(leftValue, rightValue)));
    
    arrayResults = onlyInLeft(Array1, Array2, isObject);
    //console.log(arrayResults);
    return arrayResults;
  }*/

  show_modal_new(){

    this.newForm.reset();

    const item = this.reset_form();

    this.show_modal_edit_item(item, null);
  }

  reset_form(){
    this.newForm.reset();

    this.searchTextSimilars = [];
    this.answersSimilars_array =  [];

    return {id: "0", index: "0", website: "", respuesta1: "", respuesta2: "", descuento: "", date_updated: "", idpedido: "", idusuario: ""};
  }

  check_customers_similars(item , arraySearch){

    let arrayResults = [];

    let strSearch = item.respuesta1 + ' ' + item.respuesta2;

    const strSearchLink = item.comments; //Tambien buscamos por links
    if(strSearchLink!='')
      strSearch = strSearch+' '+this.clearURL( strSearchLink );

    var words = strSearch.split(/[\s,\-.\\\/()]+/mg);//split(' '); //Comparamaos por cada palabra separada por Space coma - .
    words = this.uniqsInArray(words);

    for(var i=0; i < words.length; i++ ){

      if ( !this.ignoreSearchText.includes(words[i]) )  arrayResults = arrayResults.concat( this.searchStringInArrayObjs_Customers(arraySearch, words[i]) );
      else words[i] = null;
    }

    arrayResults = this.uniqsInArray( arrayResults );

    return arrayResults;
  }

  searchStringInArrayObjs_Customers(arraySearch, strSearch){

    let arrayResults = [];

    if(strSearch != null && strSearch != ""){

      strSearch = strSearch.toLowerCase();

      arrayResults = arraySearch.filter( obj => {
        return obj.nombre != null &&
            obj.idempresa != this.newForm.value.idempresa && //Evitamos que se muestre así misma
           ( obj.nombre.toLowerCase().indexOf( strSearch ) > -1 || 
           obj.link.toLowerCase().indexOf( strSearch ) > -1 )
           ;
      });

    }

    return arrayResults;
  }

  clearURL(str){
    return str == null ? '' : str.replace(/([A-z]+:)|\/|(\.[A-z]+.)$/g, '');
  }

  update_array_filters(form){

    const date_start = form.value.date_start ;
    const date_end = form.value.date_end ;

    var orders_array = this.answers;

    if( form.value.date_start != "" && form.value.date_start != null){

      orders_array = this.filter_by_date_start( orders_array, date_start);

    }

    if( form.value.date_end != "" && form.value.date_end != null){

      orders_array = this.filter_by_date_end( orders_array, date_end);
    }

    this.answers_array_filtered = orders_array;
  }

  date_start_onchange(form: NgForm){

    this.update_array_filters(form)
  }

  filter_by_date_start(orders_array, date_start){

    let arrayResults = [];

    arrayResults = orders_array.filter( obj => {

        return  obj.fecha >= date_start;
    });

    return arrayResults;
  }

  date_end_onchange(form: NgForm){

    this.update_array_filters(form)
  }

  filter_by_date_end(orders_array, date_end){

    let arrayResults = [];

    arrayResults = orders_array.filter( obj => {

        return  obj.fecha <= date_end;
    });

    return arrayResults;
  }

  filter_by_date_today(form = this.searchForm){

    const date = new Date();

    form.patchValue({
      date_start: this.getDate2String(date),
      date_end: this.getDate2String(date)
    })
  
    this.update_array_filters(form)
  }

  filter_by_date_week(form = this.searchForm){

    const date = new Date();

    form.patchValue({
      date_start: this.getDate2String(this.getMonday(date)),
      date_end: ""
    })

    this.update_array_filters(form)
  }

  filter_by_date_lastweek(form = this.searchForm){

    var date = new Date();
    const monday = this.getMonday( date );

    const mondayeBefore = new Date(date.setUTCDate( monday.getUTCDate()-7 ) );
    const sundayBefore = new Date(date.setUTCDate( monday.getUTCDate()-1 ) );

    form.patchValue({
      date_start: this.getDate2String( mondayeBefore ),
      date_end: this.getDate2String( sundayBefore )
    })

    this.update_array_filters(form)
  }

  getDate2String(dateObj , divider='-'){
    return dateObj.getFullYear()+divider+(dateObj.getMonth()<=9?'0':'')+(dateObj.getMonth()+1)+divider+(dateObj.getDate()<=9?'0':'')+dateObj.getDate()
  }

  getMonday(d) {
    d = new Date(d);
    var day = d.getDay(),
      diff = d.getDate() - day + (day == 0 ? -6 : 1); // adjust when day is sunday
    return new Date(d.setDate(diff));
  }

  filter_by_date_reset(form = this.searchForm){

    const date = new Date();

    form.patchValue({
      date_start: "",
      date_end: ""
    })

    this.update_array_filters(form)
  }


  /*toClipboard(website, id){ //HTTPS

    let str = document.location.hostname+"/#/allanswers/"+website+"/"+id;
    console.log("trying.... 0");
    if (window.isSecureContext && window.navigator['clipboard']) {
      window.navigator['clipboard'].writeText( str );
    } else {
      this.unsecuredToClipboard(str);
    }
  }

  unsecuredToClipboard(text){ //HTTP NO me funciona, no se porque
    const textArea = document.createElement("textarea"); console.log("trying 1....");
    textArea.value = text;
    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select(); console.log("trying1....");
    try { console.log("trying2....");
      console.log( document.execCommand('copy') );
    } catch (err) {
      console.error('Unable to copy to clipboard', err);
    }
    document.body.removeChild(textArea);
  }*/

}
