export class GlobalTranslations{

    public static translations = [
        , // 1 = ES
        {
        online_order : "PEDIDO ONLINE" ,
          name : "NOMBRE" ,
          address : "DIRECCIÓN",
          send: "ENVIO",
          company: "EMPRESA",
          province : "PROVINCIA",
          state : "ESTADO",
          email : "EMAIL",
          country : "PAÍS",
          phone : "TELÉFONO",
          city : "CIUDAD",
          line : "LINEA",
          description: "DESCRIPCIÓN",
          quanity: "CANTIDAD",
          price: "PRECIO",
          message: "MENSAJE"
        }
        , // 2 = EN
        {
        online_order : "ONLINE ORDER" ,
          name : "NAME",
          address : "ADDRESS",
          send: "SHIPMENT",
          company: "COMPANY",
          province : "PROVINCE",
          state : "STATE",
          email : "EMAIL",
          country : "COUNTRY",
          phone : "PHONE",
          city : "CITY",
          line : "LINE",
          description: "DESCRIPTION",
          quanity: "QUANTITY",
          price: "PRICE",
          message: "MESSAGE"
        }
        , // 3 = DE
        {
        online_order : "ONLINEBESTELLUNG" ,
          name : "NAME",
          address : "ADRESSE",
          send: "SENDUNG",
          company: "UNTERNEHMEN",
          province : "PROVINZ",
          state : "ZUSTAND",
          email : "EMAIL",
          country : "LAND",
          phone : "TELEFON",
          city : "STADT",
          line : "LINIE",
          description: "BESCHREIBUNG",
          quanity: "MENGE",
          price: "PREIS",
          message: "NACHRICHT"
        }
        , // 4 = FR
        {
        online_order : "COMMANDE EN LIGNE" ,
          name : "NOM",
          address : "ADRESSE",
          send: "EXPÉDITION",
          company: "ENTREPRISE",
          province : "PROVINCE",
          state : "ÉTAT",
          email : "EMAIL",
          country : "PAYS",
          phone : "TÉLÉPHONE",
          city : "VILLE",
          line : "DOUBLER",
          description: "DESCRIPTION",
          quanity: "MONTANT",
          price: "PRIX",
          message: "MESSAGE"
        } 
      ];
}