import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { GlobalConstants } from 'src/app/shared/constants/global-constants';

@Injectable({
  providedIn: 'root'
})
export class CustomersService{

  config;

  id_user=localStorage.getItem('id');

  constructor(public http: HttpClient) {
    this.config = {headers : {
        "Content-Type": "application/json; charset = utf-8;"
      }
    };
  }

  all_customers(){
    return this.http.get(`${GlobalConstants.apiURL}get_customers_final.php`);
  }

  update_customer(customer){
    var post = this.http.post(`${GlobalConstants.apiURL}set_customer.php`,JSON.stringify({
      update:customer.update,
      id:customer.id,
      comercial:customer.comercial,
      state:customer.state,
      website:customer.website
    },this.config));
    return post
  }
}
