import { Component, OnInit } from '@angular/core';
import { Subscriber } from 'rxjs';

@Component({
  selector: 'app-anuarios',
  templateUrl: './anuarios.component.html',
  styleUrls: ['./anuarios.component.less']
})
export class AnuariosComponent implements OnInit {
  empresas:any[]=[
  {nombre:"Biogram, S.L", pais:"Italia",perfil:"Gema",sector:"Moldes"},
  {nombre:"NUTRIPACK IBERICA , S.L.", pais:"España",perfil:"Esther",sector:"Particular"}
  ];
  empresa:any;
  nombre_empresa;
  pais_empresa;
  perfil_empresa;
  producto_empresa;
  sector_empresa;
  categoria;
  contactado;
  direccion;
  tlf;
  mail;
  url;
  unidades;
  constructor() { }

  ngOnInit() {
    this.abrir();
  }
abrir(){
  var iframe = document.getElementById('F|ls_table_stockFactsheet_main|item_534_E.SPOT_DF|trade.price|div');
  // var iframe = document.querySelector('#F|ls_table_stockFactsheet_main|item_534_E.SPOT_DF|trade.price|div');
  console.log(iframe);
}
}
