import { Injectable } from '@angular/core';
import { User } from '../models/user.model';

import { HttpClient } from '@angular/common/http';
import { GlobalConstants } from 'src/app/shared/constants/global-constants';

@Injectable()
export class UserService {

  private isUserLoggedIn;
  public usserLogged:User;

  config;

  constructor(public http: HttpClient) { 
  	this.isUserLoggedIn = false;
    
    this.config = {headers : {
      "Content-Type": "application/json; charset = utf-8;"
    }}; 
  }

  setUserLoggedIn(user) {
    this.isUserLoggedIn = true;
    this.usserLogged = user.user;
    localStorage.setItem('currentUser', JSON.stringify(user)); 
  }

  getUserLoggedIn() {
  	return JSON.parse(localStorage.getItem('currentUser'));
  }

  login(user){
    var post= this.http.post(`${GlobalConstants.apiURL}login.php`, JSON.stringify(user ,this.config));
    return post;
  }

  logout(){

    localStorage.clear();

    var post = this.http.get(`${GlobalConstants.apiURL}logout.php`);
    return post
  }
}