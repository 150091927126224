import { Injectable } from '@angular/core';
import { HttpClient,HttpHeaders  } from '@angular/common/http';
import { Observable } from 'rxjs';

import { GlobalConstants } from 'src/app/shared/constants/global-constants';

@Injectable({
  providedIn: 'root'
})

export class CustomerAcquisitionService {

  config;
  //fecha = new Date().toLocaleString('latin');

  constructor(public http: HttpClient) {
    
    this.config = {headers : {
      "Content-Type": "application/json; charset = utf-8;"
    }}; 
  }

  set_password(id,name,pass,new_pass){
    var post=this.http.post(`${GlobalConstants.apiURL}set_password.php`,JSON.stringify({id,name,pass,new_pass},this.config));
    return post;
  }
}
