import { Component, OnInit } from '@angular/core';
import { StatisticsService } from '../../services/statistics.service';

import { GlobalConstants } from 'src/app/shared/constants/global-constants';

@Component({
  selector: 'app-all',
  templateUrl: './statistics.component.html',
  styleUrls: ['./statistics.component.less']
})

export class StatisticsComponent implements OnInit{


  comercials_array_str = GlobalConstants.comercials_array_str;

  answers_statistics_array: any = [];
  calculos_month_statistics_array: any = [];
  calculos_year_statistics_array: any = [];
  calculos_no_month_statistics_array: any = [];
  calculos_no_year_statistics_array: any = [];
  calculos_diametro_year_statistics_array: any = [];
  calculos_diametro_month_statistics_array: any = [];
  purchases_sum_us: any = [];
  purchases_sum_eu: any = [];
  purchases_query: any = [];
  production_tubos: any = [];
  production_leads: any = [];
  seguimientos: any = [];


  p : number = 1;
  searchText: String = '';
  filterMetadata = { count: 0 };
  key: string = 'cnt';
  reverse: boolean = true;
  sort(key){
    this.key = key;
    this.reverse = !this.reverse;
  }

  p2 : number = 1;
  searchText2: String = '';
  filterMetadata2 = { count: 0 };
  key2: string = '';
  reverse2: boolean = true;
  sort2(key){
    this.key2 = key;
    this.reverse2 = !this.reverse2;
  }

  p3 : number = 1;
  searchText3: String = '';
  filterMetadata3 = { count: 0 };
  key3: string = '';
  reverse3: boolean = true;
  sort3(key){
    this.key3 = key;
    this.reverse3 = !this.reverse3;
  }

  searchText4: String = '';
  filterMetadata4 = { count: 0 };
  key4: string = '';
  reverse4: boolean = true;
  sort4(key){
    this.key4 = key;
    this.reverse4 = !this.reverse4;
  }

  searchText5: String = '';
  filterMetadata5 = { count: 0 };
  key5: string = '';
  reverse5: boolean = true;
  sort5(key){
    this.key5 = key;
    this.reverse5 = !this.reverse5;
  }

  searchText6: String = '';
  filterMetadata6 = { count: 0 };
  key6: string = '';
  reverse6: boolean = true;
  sort6(key){
    this.key6 = key;
    this.reverse6 = !this.reverse6;
  }

  searchText7: String = '';
  filterMetadata7 = { count: 0 };
  key7: string = '';
  reverse7: boolean = true;
  sort7(key){
    this.key7 = key;
    this.reverse7 = !this.reverse7;
  }

  searchText8: String = '';
  filterMetadata8 = { count: 0 };
  key8: string = '';
  reverse8: boolean = true;
  sort8(key){
    this.key8 = key;
    this.reverse8 = !this.reverse8;
  }

  searchText9: String = '';
  filterMetadata9 = { count: 0 };
  key9: string = '';
  reverse9: boolean = true;
  sort9(key){
    this.key9 = key;
    this.reverse9 = !this.reverse9;
  }

  key10: string = 'date_updated';
  reverse10: boolean = true;
  searchText10: String = '';
  filterMetadata10 = { 
    count: 0,
    filters: ["empresa", "oferta", "idcomercial_str", "date_updated", "observacion", "units", "state_array_str", "type_str"]
  };
  sort10(key){
    this.key10 = key;
    this.reverse10 = !this.reverse10;
  }

  searchText11: String = '';
  filterMetadata11 = { 
    count: 0,
    filters: ["d"]
   };
   key11: string = '';
   reverse11: boolean = true;
   sort11(key){
     this.key11 = key;
     this.reverse11 = !this.reverse11;
   }
 
  p12 : number = 1;
  searchText12: String = '';
  filterMetadata12= { 
    count: 0,
    filters: ["l"]
   };
   key12: string = '';
   reverse12: boolean = true;
   sort12(key){
     this.key12 = key;
     this.reverse12 = !this.reverse12;
   }

  /*searchText13: String = '';
  filterMetadata13 = { 
    count: 0,
    filters: ["leads"]
   };*/

   key14: string = 'd';
   filterMetadata14 = { count: 0 };
   reverse14: boolean = true;
   searchText14: String = '';
   sort14(key){
     this.key14 = key;
     this.reverse14 = !this.reverse14;
   }

   key15: string = '';
   filterMetadata15 = { count: 0 };
   reverse15: boolean = true;
   searchText15: String = '';
   sort15(key){
     this.key15 = key;
     this.reverse15 = !this.reverse15;
   }


  currentYear = new Date().getFullYear().toString();

  offer_state_array_str = GlobalConstants.offer_state_array_str;
  type_array_str = GlobalConstants.seguimiento_type_array_str;

  constructor(private answerstatisticsservice: StatisticsService ) {  

  }

  ngOnInit() {
    this.get_statistics();
  }

  get_statistics(){
    this.answerstatisticsservice.get_statistics().subscribe(
      data => {

        if(data == "logout") window.location.href = '/#/login';
        else{
          this.answers_statistics_array= data['answers'];
          this.calculos_month_statistics_array= data['calculos_month'];
          this.calculos_year_statistics_array= data['calculos_year'];
          this.calculos_no_month_statistics_array= data['calculos_no_month'];
          this.calculos_no_year_statistics_array= data['calculos_no_year'];
          this.calculos_diametro_month_statistics_array= data['calculos_diametro_month'];
          this.calculos_diametro_year_statistics_array= data['calculos_diametro_year'];
          this.purchases_sum_us= data['purchases_sum_us'];
          this.purchases_sum_eu= data['purchases_sum_eu'];
          //this.purchases_query = data['purchases_query'];
          this.seguimientos = data['seguimientos'];

          for (let i in this.seguimientos){
            this.seguimientos[i].state_array_str = this.offer_state_array_str[ this.seguimientos[i].state ].value;
            this.seguimientos[i].units = parseInt( this.seguimientos[i].units);
            this.seguimientos[i].type_str = this.type_array_str[ +this.seguimientos[i].type ].value;
            this.seguimientos[i].index = i;
          }
        }
      }
    );

    this.answerstatisticsservice.get_statistics_query().subscribe(

      data => {

          this.purchases_query = data;
      }
    );

    this.answerstatisticsservice.get_statistics_production().subscribe(

      data => {
          
          this.production_tubos = data['tubos'];
          this.production_leads = data['leads'];
      }
    );
  }
}
