import { Injectable } from '@angular/core';
import { HttpClient,HttpHeaders  } from '@angular/common/http';
import { Observable } from 'rxjs';

import { GlobalConstants } from 'src/app/shared/constants/global-constants';

@Injectable({
  providedIn: 'root'
})

export class CustomerAcquisitionService {

  config;
  //fecha = new Date().toLocaleString('latin');

  constructor(public http: HttpClient) {
    
    this.config = {headers : {
      "Content-Type": "application/json; charset = utf-8;"
    }}; 
  }

  set_customer_acquisition(customer){
    var post=this.http.post(`${GlobalConstants.apiURL}set_customer_acquisition.php`,JSON.stringify({customer},this.config));
    return post
  }

  get_customers_acquisition(filters){
    var post = this.http.post(`${GlobalConstants.apiURL}get_customers_acquisition.php`,JSON.stringify(filters,this.config));
    return post
  }

  new_remind(remind){
      var post = this.http.post(`${GlobalConstants.apiURL}new_remind.php`, JSON.stringify({remind},this.config));
      return post;
  }

  set_remind(end_notice){
    var post=this.http.post(`${GlobalConstants.apiURL}set_remind.php`,JSON.stringify({end_notice},this.config));
    return post
  }

  get_reminds(idcomercial){
    var post = this.http.post(`${GlobalConstants.apiURL}get_reminds.php`,JSON.stringify({idcomercial},this.config));
    return post
  }

  get_comission(){
    return this.http.get(`${GlobalConstants.apiURL}get_comission.php`);
  }

  change_pay_commssion(id,pay,fecha_pay,quantity,currency){
    var post = this.http.post(`${GlobalConstants.apiURL}change_pay_commission.php`,JSON.stringify({id,pay,fecha_pay,quantity,currency},this.config));
    return post
  }

  get_labels_no_sended(){
    return this.http.get(`${GlobalConstants.apiURL}get_labels_no_sended.php`);
  }

  get_labels_sended(){
    return this.http.get(`${GlobalConstants.apiURL}get_labels_sended.php`);
  }

  get_lists(){
    return this.http.get(`${GlobalConstants.apiURL}get_lists.php`);
  }

  get_comercials(){
    return this.http.get(`${GlobalConstants.apiURL}get_comercials.php`);
  }
  get_sources(){
    return this.http.get(`${GlobalConstants.apiURL}get_sources.php`);
  }
  get_sections(){
    return this.http.get(`${GlobalConstants.apiURL}get_sections.php`);
  }
  get_products(){
    return this.http.get(`${GlobalConstants.apiURL}get_products_type.php`);
  }

  get_comercial_activities(){
    return this.http.get(`${GlobalConstants.apiURL}get_comercial_activities.php`);
  }

  get_customers_count(){
    return this.http.get(`${GlobalConstants.apiURL}get_customers_count.php`);
  }
}
