import { Component, OnInit, Injectable, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators, NgForm, FormControl  } from '@angular/forms';

import { CustomerAcquisitionService } from '../../../core/services/customer_acquisition.service';
import { GlobalConstants } from '../../constants/global-constants';
import { GlobalFunctions } from '../../constants/global-functions';

@Component({
  selector: 'app-modaleditcustomer',
  templateUrl: './modal_edit_customer.component.html',
  styleUrls: ['./modal_edit_customer.component.less']
})
@Injectable({
  providedIn: 'root'
})
export class ModalViewCustomerComponent implements OnInit {
  //[x: string]: any;

  @Input() newForm: FormGroup;
  @Input() input_form_disabled; //null or true
  @Input() FormTitleString:string;
  @Input() fecha_create:string;
  @Input() fecha_edit:string;
  @Input() customer:any;
  @Input() customers_array:any = [];

  isCollapsed:boolean = false;

  customersSimilars_array: any = [];

  //@Input() public myParentData;
  //@Input('myParentData') public myNewVariable;  
      
  alertshow:boolean = false;

  response = [];

  yesno_array_str = GlobalConstants.yesno_array_str;
  nuevo_array_str = GlobalConstants.nuevo_array_str;
  status_array_str = GlobalConstants.status_array_str;
  lists_array_str = GlobalConstants.lists_array_str;
  sectors_array_str = GlobalConstants.sectors_array_str;
  products_array_str = GlobalConstants.products_array_str;
  destacado_array_str = GlobalConstants.destacado_array_str;
  shipping_agencies_array_str = GlobalConstants.shipping_agencies_array_str;
  countries_array_str = GlobalConstants.countries_array_str;
  procedencia_array_str = GlobalConstants.procedencia_array_str;
  comercials_array_str = GlobalConstants.comercials_array_str;

  user_type:string = GlobalConstants.user_type;
  user_id:string = GlobalConstants.user_id;

  arrayWords: any = [];
  
  constructor(private customerAcquisitionService: CustomerAcquisitionService, private formBuilder: FormBuilder) {
    
    this.init_form();
  }

  ngOnInit() {}

  alert_show(){
    this.alertshow = true;

    setTimeout(() => {
      this.alert_hide();
    }, 2000);
  }

  alert_hide(){
    this.alertshow = false;
  }

  init_form(){    
    this.newForm = this.formBuilder.group({
      'idempresa': new FormControl({value: '' }, Validators.required),
      'nombre' : '', //new FormControl({value: '', disabled: input_form_disabled }, Validators.required),
      'destacado' : '',
      'idcomercial' : '',
      'idcomercial_original' : '', //Si cambiamos de comercial no podremos guardar el cambio
      'direccion' : '',
      'city' : '',
      'province' : '',
      'pais' : '',
      'pais_old' : new FormControl({value: '', disabled: true }), //Solución temporal mientras no se haya estandarizado
      'cp' : '',
      'tlf' : '',
      'tlf2' : '',
      'contact': '',
      'mail' : '',
      'mail2' : '',
      'producto' : '',
      'idlista' : '',
      'sector' : '',
      'nota' : '',
      'link' : '',
      'status' : '',
      'unidades' : '',
      'nuevo' : '',
      'comentario' : '',
      'idprocedencia' : '',

      'agency' : '',
      'tracking' : '',
      'date_sending' : '',
      'date_received' : '',
      'time_received' : '',
      'fecha_recor' : '',
      'firmado' : '',
      'send' : '',

      'submit' : new FormControl({value: true }) //Chapuza: Prevent sumbit on window.open
    });
  }

  show_modal(item:any){

    this.newForm.reset();

    this.newForm.patchValue(item);

    this.newForm.patchValue({
 
      date_sending: GlobalFunctions.datetime_to_date(item.date_sending),
      pais_old: item.pais,
      idcomercial_original: item.idcomercial
    });
  
    //this.newForm.get('destacado').enable();
    //this.view_register_note(idcompany);
  }

  del_item(item:any){

    this.customerAcquisitionService.delete_customer_acquisition(item.idempresa).subscribe(
      result => {
        
        if(result['resultado'] == 'OK') {
          this.response=result['mensaje'];

          document.getElementById("viewcustomerModal").style.display =
          document.getElementById("delModal").style.display = "none";
          var modal;
          modal as HTMLCollectionOf<HTMLElement>;
          modal = document.getElementsByClassName("modal-backdrop")[1];
          modal.style.display = "none";
          modal = document.getElementsByClassName("modal-backdrop")[0];
          modal.style.display = "none";
      }
    });

    Object.assign( item , { nombre: null, pais : null, status : null, id : null } );
  }

  print(){ 
    window.print();
  }

  submit(form: NgForm){

    if( form.dirty && form.valid && form.value.submit){

      form.value.user_id = this.user_id;

      if(form.value.idempresa == 0){ //Es nuevo cliente

          this.customerAcquisitionService.new_customer_acquisition(
            form.value
          ).subscribe(
             result => {
              this.On_Customer_Updated(result, form);
          });

      }else{

        this.customerAcquisitionService.set_customer_acquisition(
          form.value
        ).subscribe(
          result => {
            this.On_Customer_Updated(result, form);
        });
      }

    }
  }

  view_tracking(agency,tracking){ 

    if(tracking!=null && tracking=='' && agency!=null && agency!=''){

      var url = '';

      switch(agency.toLowerCase()){
        case 'ups' : url = 'https://www.ups.com/track?loc=es_ES&tracknum='+tracking;
        case 'envialia' : url = 'https://www.envialia.com/seguimiento/?';
        case 'mrw' : url = 'https://www.mrw.es/seguimiento/envio-actual.asp';
      }

      this.open_url(url);
    }
    
  }

  open_url(url){

    this.newForm.value.submit = false;

    let pattern1 = /^http(s*):\/\/{1}/;
    let pattern2 = /^mailto:/;

    if( !pattern1.test(url) && !pattern2.test(url)) url = 'http://' + url;

    if(url!='') window.open(url, "_blank");
  }

  On_Customer_Updated(result, form){

    if(result['resultado'] == 'OK') {
      this.response=result['mensaje'];
      
      this.alert_show();

      if(form.value.idempresa == 0){
        this.newForm.patchValue({
          nombre: ''
        })
      }

      else Object.assign( this.customer, form.value );
    }
  }

  check_customers_similars(){

    let arrayResults = [];

    const arraySearch = this.customers_array;

    let strSearch = this.newForm.value.nombre;

    const strSearchLink = this.newForm.value.link; //Tambien buscamos por links
    if(strSearchLink!='')
      strSearch = strSearch+' '+this.clearURL( strSearchLink );

    var words = strSearch.split(/[\s,-.]+/);//split(' '); //Comparamaos por cada palabra separada por Space coma - .
    words = this.uniqsInArray(words);

    this.arrayWords = words;

    for(var i=0; i < words.length; i++ ){

      arrayResults = arrayResults.concat( this.searchStringInArrayObjs(arraySearch, words[i]) );
    }

    arrayResults = this.uniqsInArray( arrayResults );

    this.customersSimilars_array = arrayResults;
  }

  searchStringInArrayObjs(arraySearch, strSearch){

    let arrayResults = [];

    if(strSearch != null && strSearch != ""){

      strSearch = strSearch.toLowerCase();

      arrayResults = arraySearch.filter( obj => {
        return obj.nombre != null &&
            obj.idempresa != this.newForm.value.idempresa && //Evitamos que se muestre así misma
           ( obj.nombre.toLowerCase().indexOf( strSearch ) > -1 || 
           obj.link.toLowerCase().indexOf( strSearch ) > -1 )
           ;
      });

    }

    return arrayResults;
  }

  clearURL(str){
    return str == null ? '' : str.replace(/([A-z]+:)|\/| |(\.[A-z]+.)$/g, '');
  }

  /**
   * Remove Duplications in Array
   * 
   * @param Array 
   * @returns Array
   */
  uniqsInArray(Array) {
    var prims = {"boolean":{}, "number":{}, "string":{}}, objs = [];

    return Array.filter(function(item) {
        var type = typeof item;
        if(type in prims)
            return prims[type].hasOwnProperty(item) ? false : (prims[type][item] = true);
        else
            return objs.indexOf(item) >= 0 ? false : objs.push(item);
    });
  }

}
