import { Component, OnInit,ViewChild } from '@angular/core';
import { LoginComponent } from '../../pages/login/login.component';
import { CustomerAcquisitionService } from '../../services/navbar.service'
import { User } from '../../models/user.model';
import { UserService } from '../../services/user.service';
import { Router } from '@angular/router';
import {Md5} from 'ts-md5/dist/md5';
@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.less']
})
export class NavbarComponent implements OnInit {
  email;
  name;
  type;
  id;
  alert:boolean = false;
  alert1:boolean = false;
  msj;
  error;
  true_msj;
  new_pass=null;
  re_pass=null;
  pass=null;
  user;
  new_password;

  constructor(private usuarioservice: CustomerAcquisitionService,private userService: UserService,private router: Router) { }

  ngOnInit() {
    this.email=localStorage.getItem('email');
    this.name=localStorage.getItem('nombre');
    this.type=localStorage.getItem('tipo');
    this.id=localStorage.getItem('id');
  }

  logout(){
    this.userService.logout();
  }

  /*navigate(){  
    if(this.type.indexOf('admin') > -1){
      this.router.navigate(['/counters/']);
    }else if(this.type.indexOf('comercial') > -1){
      this.router.navigate(['/commercial/',this.id,this.name]);
    }else
    this.router.navigate(['/commercial/',this.id,this.name]);
  }*/

  pass_change(pass,new_pass,re_pass){

    if((pass!==null && new_pass!==null && re_pass!==null)){

      if(new_pass!=re_pass){
          this.hides2(this.alert=true);
        }else
          
        if(new_pass==re_pass){
          let md5= new Md5();
              var password=md5.appendStr(pass).end();

              if(this.userService.getUserLoggedIn().pass==password){
                
                  this.usuarioservice.set_password(this.id,this.name,pass,new_pass).subscribe(

                  data => {
                
                    this.true_msj=data['true_msj'];
                    this.error=data['error'];
                    if(this.true_msj!==null){
                      
                      setTimeout(() => {this.alert=false; this.close_modal();}, 1500);
                      
                      let u: User =this.userService.getUserLoggedIn().user;
                      let md5= new Md5();
                      this.new_password=md5.appendStr(new_pass).end();
                      
                      let p: User =this.new_password;
                      
                      this.userService.setUserLoggedIn({user: u, password: p});
                      
                    }else if(this.error==!null){
                      this.pass=null;
                      this.hides3(this.alert1=true);
                    }
                  
                  }
                );
              }else 
                { 
                this.hides3(this.alert1=true);
                setTimeout(() => {this.alert=false}, 1500);}
          } return;

      }return
    
  }
  hides2(alert){
    if(this.alert=alert){
      this.msj="Tu nueva contraseña no conincide";
      this.new_pass=null;
      this.re_pass=null;
      setTimeout(() => {this.alert=false}, 1500);
    }
  }
  hides3(alert){
    if(this.alert1=alert){
      this.error="La contraseña no es correcta";
      this.pass=null;
      setTimeout(() => {this.alert1=false}, 1500);
    }
  }

  close_modal(){
    document.getElementById("modal").style.display = "none";
    var modal;
    modal as HTMLCollectionOf<HTMLElement>;
    modal = document.getElementsByClassName("modal-backdrop")[0];
    modal.style.display = "none";

    this.new_pass=null;
    this.re_pass=null;
    this.pass=null;
    this.alert1=false;
    this.alert=false;
  }
           
}
