import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, NgForm, Validators } from '@angular/forms';
import { User } from '../../models/user.model';
import { UserService } from '../../services/user.service';
import {Md5} from 'ts-md5/dist/md5';
import { GlobalConstants } from 'src/app/shared/constants/global-constants';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.less']
})
export class LoginComponent implements OnInit {
  loginForm: FormGroup;
  error: string;

  show:boolean = false;

  //on:number=1;
  constructor(

    private router: Router,
    private formBuilder: FormBuilder,
    private userService: UserService,

  ) {
    this.loginForm = formBuilder.group({
      'username': ['', Validators.required],
      'password': ['', Validators.required]
  });

  }

  //@ViewChild(NavbarComponent) navbar:NavbarComponent;

  ngOnInit() {

    if(this.userService.getUserLoggedIn()!= null){
      let obj= this.userService.getUserLoggedIn();
      const username=obj['user'];
      const passwordEncrypted=obj['pass'];
      const user={username: username, password: passwordEncrypted};
      this.userService.login(user).subscribe(
        result => {
          this.login(user, result);
      });

    }else{
      this.userService.logout().subscribe(
        result => {
          console.log(result);
      });
    }

  }

  submit(form: NgForm){

    if(form.dirty && form.valid){

      const md5 = new Md5();
      const passwordEncrypted=md5.appendStr(form.value.password).end();
      const user={ username: form.value.username, password: passwordEncrypted};
      
      this.userService.login(user).subscribe(
        result => {
          this.login(user, result);
        }
      );

    }
  }

  login(user, result){
    
    const username=result['usuario'];
    const passwordEncrypted=result['clave'];
    const active=result['activo'];

    if(username==user.username && passwordEncrypted==user.password){

      const name=result['nombre'];
      const type=result['tipo'];
      const id=result['id'];
      const email=result['email'];

      //let username: User = username;
      let password: User = passwordEncrypted;
      this.userService.setUserLoggedIn({user: username, pass: password});

      localStorage.setItem('nombre', name);
      localStorage.setItem('email', email);
      localStorage.setItem('tipo', type);
      localStorage.setItem('id', id);

      GlobalConstants.user_type = type;
      GlobalConstants.user_id = id;
      
      if( type.indexOf('marketing') > -1){
        this.router.navigate(['/allanswers']);
      }if( type.indexOf('gerente') > -1){
        this.router.navigate(['/statistics']);
      }else{
        this.router.navigate(['/customersacquisitions/', id, name]);
      }

    }else{
      //this.error=result['mensaje'];
      this.error='You are using the wrong Username or Password!';

      this.show = !this.show;
      if(this.show=true){
        setTimeout(() => {
          this.hides();
        }, 3000);
      }
    }

  }

  hides(){
    if(this.show=false){
      this.show;
    }
  }
}
