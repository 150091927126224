import { Component, OnInit } from '@angular/core';
import { PPricesService } from '../../services/pprices.service';

import { GlobalConstants } from 'src/app/shared/constants/global-constants';

@Component({
  selector: 'app-all',
  templateUrl: './pprices.component.html',
  styleUrls: ['./pprices.component.less']
})

export class PPricesComponent implements OnInit{

  comercials_array_str = GlobalConstants.comercials_array_str;

  pprices_array: any = [];

  key: string = 'fecha';
  reverse: boolean = true;
  sort(key){
    this.key = key;
    this.reverse = !this.reverse;
  }

  searchText: String = '';
  filterMetadata = { count: 0 };

  constructor(private ppricesservice: PPricesService ) {  

  }

  ngOnInit() {
    this.get_pprices();
  }

  get_pprices(){
    this.ppricesservice.get_pprices().subscribe(
      /*data => //console.log(data)
      this.answers= data*/
      data => {

        if(data == "logout") window.location.href = '/#/login';
        else{
          this.pprices_array= data;
        }
      }
    );    
  }
}
