import { Component, OnInit,Output } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { CustomerAcquisitionService } from '../../services/comercials.service';

@Component({
  selector: 'app-counters',
  templateUrl: './counters.component.html',
  styleUrls: ['./counters.component.less']
})

export class CountersComponent implements OnInit {
  profile;
  contadores;
  conteos;
  idcomercial;
  names;
  name;
  sectores;
  produce;
  roster;
  id_empresa;
  nombre;
  direccion;
  tlf;
  tlf2;
  contact;
  mail;
  mail2;
  nuevo;
  producto;
  sector;
  lista;
  categoria;
  idprocedencia;
  fecha_create;
  fecha_edit;
  logo;
  pais;
  city;
  province;
  cp;
  link;
  nota;
  unidades;
  comercial;
  client;
  status;
  destacado;
  important;
  estado;
  modify;
  client_contact;
  key: string = 'destacado';
  reverse: boolean = false;
  sort(key){
    this.key = key;
    this.reverse = !this.reverse;
  }
  p: number = 1;
  response;
  show:boolean = false;
  hide;
  type;
  user_name;
  total;
  constructor(private router: Router,private usuarioservice: CustomerAcquisitionService, private route:ActivatedRoute) { }


  ngOnInit() {
    this.list();
    this.section();
    this.product();
    this.perfil();
    this.count();
    this.count_all();
    this.type=localStorage.getItem('tipo');
    this.user_name=localStorage.getItem('nombre');
    //console.log(this.type);
    

  }
  perfil(){
    this.usuarioservice.get_comercials().subscribe(
      data => this.profile=data
  );
  }
  list(){
    this.usuarioservice.get_lists().subscribe(
      data => this.roster=data
  );
  }
  section(){
    this.usuarioservice.get_sections().subscribe(
      data => this.sectores=data
    );
  }
  
  product(){
    this.usuarioservice.get_products().subscribe(
      data => this.produce=data
    );
  }

  count(){
    this.usuarioservice.get_comercial_activities().subscribe(
      data => 
      // console.log(data['0'])
      this.contadores=data
  );
  
  }

  count_all(){
    this.usuarioservice.get_customers_count().subscribe(
      data => 
      // console.log(data)
      this.total=data
  );
  
  }

  seek(name){
    /*this.hide='hide';
    this.usuarioservice.get_customers_by_name(name).subscribe(
      data => this.names=data
  );*/

  }
  open_all(idcompany){
    /*this.id_empresa=idcompany;
      this.usuarioservice.get_customer_acquisition(this.id_empresa).subscribe(
        data => 
        {
          this.nombre= data['nombre'];
          this.direccion= data['direccion'];
          this.tlf= data['tlf'];
          this.tlf2= data['tlf2'];
          this.mail= data['mail'];
          this.mail2= data['mail2'];
          this.nuevo= data['nuevo'];
          this.producto= data['producto'];
          this.sector= data['sector'];
          this.lista= data['idlista'];
          this.categoria= data['nom_list'];
          this.idprocedencia= data['idprocedencia'];
          this.idcomercial=data['idcomercial'];
          this.comercial= data['comercial'];
          this.fecha_create= data['fecha_create'];
          this.fecha_edit= data['fecha_edit'];
          this.logo= data['logo'];
          this.city=data['city'];
          this.province=data['province'];
          this.cp=data['cp'];
          this.pais= data['pais'];
          this.link= data['link'];
          this.nota= data['nota'];
          this.unidades= data['unidades'];
          this.destacado=data['destacado'];
          this.status=data['status'];

                    
          if(this.fecha_create==null){
            
          }else{
            this.fecha_create= new Date(data['fecha_create']).toLocaleString('latin');
          }

          if(this.fecha_edit==null){
            
          }else{
            this.fecha_edit= new Date(data['fecha_edit']).toLocaleString('latin');
          }
          
          switch (this.destacado){
            case '0': this.important = 'Normal';            break;
            case '1': this.important = 'Importante';        break;
            case '2': this.important = 'Muy importante';    break;
            case '3': this.important = 'Pendiente de valorar';    break;
            default: this.important = 'none';
            
          }
          switch (this.nuevo){
            case '0': this.client = 'No';                   break;
            case '1': this.client = 'Si';                   break;
            case '1': this.client = 'Repesca';              break;
            case '1': this.client = 'Cliente';              break;
            default: this.important = 'none';
            
          }
          switch (this.status){
            case '1': this.estado = 'En Espera';                      break;
            case '2': this.estado = 'Preparado';                      break;
            case '3': this.estado = 'Contactado';                     break;
            case '4': this.estado = 'Envio de muestras';              break;
            case '5': this.estado = 'Petición de precio';             break;
            case '6': this.estado = 'Pedido';                         break;
            case '7': this.estado = 'No interesado';                  break;
            case '8': this.estado = 'Ya es cliente';                  break;
            case '9': this.estado = 'No usa resistencias';            break;
            case '10': this.estado = 'Envio muestras personalizadas'; break;
            case '11':this.estado = 'Nos contactaran ellos';          break;
            case '12':this.estado = 'Visitar Cliente';                break;
            case '13':this.estado = 'Email presentación enviado';     break;
            case '14':this.estado = 'Volver a contactar';             break;
            default: this.estado = 'none';
              
          }//console.log(this.id_empresa);

        } 
    );*/
    
  }
  go(){
    var web=this.link
    if((this.link.startsWith('www')) || (this.link.startsWith('WWW'))){
       web = 'http://'+this.link;
       //console.log(web)
      window.open(this.link, "_blank");
    }else
    window.open(this.link, "_blank");
  }

  edit(nombre:string,direccion:string,tlf:string,tlf2:string,contact:string,mail:string,mail2:string,nuevo:number,producto:string,sector:string,idlista:string,idprocedencia:string,idcomercial:string,fecha_edit:string,pais:string,city:string,province:string,cp:string,link:string,nota:string,unidades:number,status:string){
    
    //fecha_edit=this.usuarioservice.fecha;
    var idcompany=this.id_empresa
    this.modify={idcompany,nombre,direccion,tlf,tlf2,contact,mail,mail2,nuevo,producto,sector,idlista,idprocedencia,idcomercial,fecha_edit,pais,city,province,cp,link,nota,unidades,status};
    //console.log(this.modify);
    this.usuarioservice.set_customer_acquisition(this.modify).subscribe(
      result => {
        if(result['resultado'] == 'OK') {
          this.response=result['mensaje'];
          this.show = !this.show;
          if(this.show=true){
            setTimeout(() => {
              this.hides();
            }, 2000);
          }  
      } 
      this.seek(name);
      this.perfil();
      this.count();
    });
    
  }
  hides(){
    if(this.show=false){
      this.show;
    }
  }
  navigate(id,nombre,tipo){
    // this.router.navigate(['/commercial/',id,nombre]);
    var search=tipo;
    //console.log(this.type);
    
    if(this.type.indexOf('admin') > -1){
      this.router.navigate(['/commercial/',id,nombre]);
      //console.log(search);
    }else
    if(this.user_name==nombre && this.type==tipo ){
      this.router.navigate(['/commercial/',id,nombre]);
    }
  }

  contact_(contact:string,direccion:string,tlf:string,tlf2:string,mail:string,mail2:string,link:string){
    /*var idcompany=this.id_empresa
    this.client_contact={idcompany,contact,direccion,tlf,tlf2,mail,mail2,link};
    this.usuarioservice.new_contact(this.client_contact).subscribe(
      result => {
        if(result['resultado'] == 'OK') {
        this.response=result['mensaje'];
        this.show = !this.show;
        if(this.show=true){
          setTimeout(() => {
            this.hides();
          }, 2000);
        }  
      }
    });*/
  }
}
