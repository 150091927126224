import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { GlobalConstants } from 'src/app/shared/constants/global-constants';

@Injectable({
  providedIn: 'root'
})
export class StatisticsService{

  config;

  //id_user=localStorage.getItem('id');

  constructor(public http: HttpClient) {
    this.config = {headers : {
        "Content-Type": "application/json; charset = utf-8;"
        //"Access-Control-Allow-Origin": "*"
      }
    };
  }

  get_statistics(){
    return this.http.get(`${GlobalConstants.apiURL}get_statistics.php`);
  }

  get_statistics_query(){
    //Para que funcione solicitar a otro dominio hay que poner cabeceras en el PHP 
    //y desabilitar el flag #Block insecure private network requests
    //¿y Insecure origins treated as secure?
    let response = this.http.get(
      `http://192.168.1.48:8080/get_orders.php?ejercicio=`+ new Date().getFullYear() 
    );

    return response;
  }

  get_statistics_production(){
    //Para que funcione solicitar a otro dominio hay que poner cabeceras en el PHP 
    //y desabilitar el flag #Block insecure private network requests
    //¿y Insecure origins treated as secure?
    let response = this.http.get(
      `http://produccion.maxiwatt.lo/ajax/statistics.php?ejercicio=`+ new Date().getFullYear() 
    );

    return response;
  }
  
  
}
