import { Injectable } from '@angular/core';
import { HttpClient,HttpHeaders  } from '@angular/common/http';
import { Observable } from 'rxjs';

import { GlobalConstants } from 'src/app/shared/constants/global-constants';

@Injectable({
  providedIn: 'root'
})

export class CustomerAcquisitionService {

  config;
  //fecha = new Date().toLocaleString('latin');

  constructor(public http: HttpClient) {
    
    this.config = {headers : {
      "Content-Type": "application/json; charset = utf-8;"
    }}; 
  }

  new_customer_acquisition(customer){ 
    var post=this.http.post(`${GlobalConstants.apiURL}new_customer_acquisition.php`,JSON.stringify({customer},this.config));
    return post
  }

  /*get_lists(){
    return this.http.get(`${GlobalConstants.apiURL}get_lists.php`);
  }

  get_comercials(){
    return this.http.get(`${GlobalConstants.apiURL}get_comercials.php`);
  }
  get_sources(){
    return this.http.get(`${GlobalConstants.apiURL}get_sources.php`);
  }
  get_sections(){
    return this.http.get(`${GlobalConstants.apiURL}get_sections.php`);
  }
  get_products(){
    return this.http.get(`${GlobalConstants.apiURL}get_products_type.php`);
  }

  get_comercial_activities(){
    return this.http.get(`${GlobalConstants.apiURL}get_comercial_activities.php`);
  }

  get_customers_count(){
    return this.http.get(`${GlobalConstants.apiURL}get_customers_count.php`);
  }

  new_customer_by_url(links){
    var post =this.http.post(`${GlobalConstants.apiURL}new_customer_by_url.php`, JSON.stringify({links,name_user:GlobalConstants.user_name,mail_user:GlobalConstants.user_mail},this.config));
     return post;
  }*/
  get_customers_acquisitions(){
   return this.http.get(`${GlobalConstants.apiURL}get_customers_acquisitions.php`);
  }

  /*get_customers_urgents(idcomercial=null,type=null){
    return this.http.post(`${GlobalConstants.apiURL}get_urgents.php`, JSON.stringify({idcomercial,type},this.config));
  }

  new_contact(client_contact){
    var post = this.http.post(`${GlobalConstants.apiURL}new_contact.php`,JSON.stringify({client_contact},this.config));
    return post
  }*/

  set_customer_acquisition(customer){
    var post=this.http.post(`${GlobalConstants.apiURL}set_customer_acquisition.php`,JSON.stringify({customer},this.config));
    return post
  }

  get_customers_acquisition(filters){
    var post = this.http.post(`${GlobalConstants.apiURL}get_customers_acquisition.php`,JSON.stringify(filters,this.config));
    return post
  }

  new_remind(remind){
      var post = this.http.post(`${GlobalConstants.apiURL}new_remind.php`, JSON.stringify({remind},this.config));
      return post;
  }

  /*set_remind(end_notice){
    var post=this.http.post(`${GlobalConstants.apiURL}set_remind.php`,JSON.stringify({end_notice},this.config));
    return post
  }*/

  get_reminds(idcomercial){
    var post = this.http.post(`${GlobalConstants.apiURL}get_reminds.php`,JSON.stringify({idcomercial},this.config));
    return post
  }

  /*get_remind_by_customer(idcompany){
    var post = this.http.post(`${GlobalConstants.apiURL}get_remind_by_customer.php`,JSON.stringify({idcompany},this.config));
    return post
  }

  get_customers_by_name(name){
    var post=this.http.post(`${GlobalConstants.apiURL}get_customers_by_name.php`,JSON.stringify({name},this.config));
    return post
  }*/

  /*get_customers_send_samples(){
    return this.http.get(`${GlobalConstants.apiURL}get_customers_send_samples.php`);
  }

  print_label(idcompany, copy, lan){
    var post=this.http.post(`${GlobalConstants.apiURL_lo}print_label.php`,JSON.stringify({idcompany, copy, lan},this.config));
    return post
  }
  // Reimprimir etiquetas CRM
  print_label2(idcompany, copy, lan, reprint){
    var post=this.http.post(`${GlobalConstants.apiURL_lo}print_label.php`,JSON.stringify({idcompany, copy, lan, reprint},this.config));
    return post;
  }

  print_label_amazon(datos){
    var post=this.http.post(`${GlobalConstants.apiURL_lo}print_tag_amazon.php`,JSON.stringify({datos},this.config));
    return post
  }

  get_labels(){
    return this.http.get(`${GlobalConstants.apiURL}get_labels.php`);
  }

  get_customers_without_check(){
    return this.http.get(`${GlobalConstants.apiURL}get_customers_without_check.php`);
  }

  get_customers_declined(){
    return this.http.get(`${GlobalConstants.apiURL}get_customers_declined.php`);
  }

  set_customer_checked(verify,n){
    var post=this.http.post(`${GlobalConstants.apiURL}set_customer_checked.php`,JSON.stringify({verify,n},this.config));
    return post
  }

  new_shipping(id_company,nombre,direccion,tlf,tlf2,contact,producto,idcomercial,pais,city,province,cp,agency,tracking,date_sending,send_example,email){
    var post=this.http.post(`${GlobalConstants.apiURL}new_shipping.php`,JSON.stringify({id_company,nombre,direccion,tlf,tlf2,contact,producto,idcomercial,pais,city,province,cp,agency,tracking,date_sending,send_example,email},this.config));
    return post
  }

  set_password(id,name,pass,new_pass){
    var post=this.http.post(`${GlobalConstants.apiURL}set_password.php`,JSON.stringify({id,name,pass,new_pass},this.config));
    return post;
  }

  send_mail_piece(idempresa){
    var post= this.http.post(`${GlobalConstants.apiURL}send_mail_piece.php`,JSON.stringify({idempresa},this.config));
    return post;
  }

  send_mail_preparado(idempresa){
    var post= this.http.post(`${GlobalConstants.apiURL}send_mail_preparado.php`,JSON.stringify({idempresa},this.config));
    return post;
  }

  send_mail_shipment_sample(idempresa){
    var post= this.http.post(`${GlobalConstants.apiURL}send_mail_shipment_sample.php`,JSON.stringify({idempresa},this.config));
    return post;
  }

  send_mail_order_end(idempresa){
    var post= this.http.post(`${GlobalConstants.apiURL}send_mail_order_end.php`,JSON.stringify({idempresa},this.config));
    return post;
  }*/

  new_note(fecha_register,register_note,id_commercial,id_company){
    var post= this.http.post(`${GlobalConstants.apiURL}new_note.php`,JSON.stringify({fecha_register,register_note,id_commercial,id_company},this.config));
    return post;
  }

  get_note(id_company){
    var post = this.http.post(`${GlobalConstants.apiURL}get_note.php`,JSON.stringify({id_company},this.config));
    return post
  }

  delete_note(id){
    var post = this.http.post(`${GlobalConstants.apiURL}delete_note.php`,JSON.stringify({id},this.config));
    return post
  }

  /*get_comission(){
    return this.http.get(`${GlobalConstants.apiURL}get_comission.php`);
  }

  change_pay_commssion(id,pay,fecha_pay,quantity,currency){
    var post = this.http.post(`${GlobalConstants.apiURL}change_pay_commission.php`,JSON.stringify({id,pay,fecha_pay,quantity,currency},this.config));
    return post
  }

  get_labels_no_sended(){
    return this.http.get(`${GlobalConstants.apiURL}get_labels_no_sended.php`);
  }

  get_labels_sended(){
    return this.http.get(`${GlobalConstants.apiURL}get_labels_sended.php`);
  }*/

  /*estimateall(id){
    var post = this.http.post(`${GlobalConstants.apiURL}estimate_all.php`,JSON.stringify({id},this.config));
    return post;
  }*/

  /*business_intranet(){
    return this.http.get(`${GlobalConstants.apiURL}business_IN.php`);
  }*/

  /*open_linea_estimate(ejercicio,numero,idempresa){
    var post = this.http.post(`${GlobalConstants.apiURL}estimate_linea.php`,JSON.stringify({ejercicio,numero,idempresa},this.config));
    return post;
  }*/

  get_links_like(id){
    var post = this.http.post(`${GlobalConstants.apiURL}get_links_like.php`,JSON.stringify({id},this.config));
    return post;
  }

  get_customers_by_comercial(sector,letra,id){
    var post = this.http.post(`${GlobalConstants.apiURL}get_customers_by_comercial.php`,JSON.stringify({sector,letra,id},this.config));
    return post;
  }

  delete_customer_acquisition(id){
    var post = this.http.post(`${GlobalConstants.apiURL}delete_customer_acquisition.php`,JSON.stringify({id},this.config));
    return post;
  }
}
