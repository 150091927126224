export class GlobalFunctions{

    public static datetime_to_date(date){

      var year=new Date (date).toLocaleString('latin', { year: 'numeric'});
      var month=new Date (date).toLocaleString('latin', { month: '2-digit'});
      var day=new Date (date).toLocaleString('latin', { day: '2-digit'});
      var s='-';
  
      return (year+s+month+s+day);
    }
}


