import { Component, OnInit } from '@angular/core';

import { FormBuilder, FormGroup, Validators,NgForm } from '@angular/forms';

@Component({
  selector: 'app-web',
  templateUrl: './cartridge_price.component.html',
  styleUrls: ['./cartridge_price.component.css']
})

export class CartridgePriceComponent implements OnInit {

  newForm: FormGroup;

  cartridgeheater = new cartridgeheater();

  currency_table = [
    { value: 1, symbol:'€'} ,
    { value: 1.1, symbol:'$'} 
  ];
  currency = this.currency_table[0];

  current_year = new Date().getFullYear();
  default_year = 2023;

  current_prices = {};
  prices = {

    2023 : {

      componentes_table : { //NO TOCAR
        "info":"arandela:   tubo:mm   disco:kg    piedra:varius mm    hilo:kg   cabezal:kg    cable:    niquel:kg   pasta:kg    tapon:unidad    oxido:KG",
        "6.50" : { arandela: 6.6, tubo: 7.5, disco:6.3, piedra: 4.2, hilo: 0.07, cabezal: 6.3, cable: 0.22, niquel: 0.7, pasta: 0, tapon: 6.6,  oxido: 'fina' },
        "8.00" : { arandela: 8.1, tubo: 9, disco: 7.7, piedra: 5, hilo: 0.10, cabezal: 7.7, cable: 0.5, niquel: 0.8, pasta: 0, tapon: 8.1,  oxido: 'fina' },
        "10.00" : { arandela: 10.1, tubo: 11.5, disco: 9.8, piedra: 6.5, hilo: 0.15, cabezal: 9.8, cable: 0.75, niquel: 1, pasta: 0, tapon: 10.1,  oxido:'fina' },
        "12.50" : { arandela: 12.3, tubo: 14.5, disco: 12.3, piedra:8.5, hilo: 0.25, cabezal: 12.3, cable: 1, niquel: 1.3, pasta: 0, tapon: 13,  oxido: 'gorda' },
        "16.00" : { arandela: 16.1, tubo: 18, disco: 15.75, piedra: 12, hilo: 0.30, cabezal: 15.75, cable: 1.5, niquel: 1.5, pasta: 0, tapon: 16.1,  oxido:  'gorda' },
        "20.00" : { arandela: 20.1, tubo: 22, disco: 18.7, piedra: 15, hilo: 0.40, cabezal: 18.7, cable: 2.5, niquel: 2, pasta: 0, tapon: 20.1,  oxido: 'gorda' }
      },

      componentes_quantity_table : { //NO TOCAR
        "info" : "",
        "6.50" : { arandela: 1, tubo: 0.001, disco:0.0005, piedra: (1/220), hilo: 0.001*0.002, cabezal: 0.0005, cable: 0.001, niquel: 0.360*0.0005, pasta: 0.001, tapon: 1,  oxido: 0.04/1000 },
        "8.00" : { arandela: 1, tubo: 0.001, disco:0.001, piedra: (1/220), hilo: 0.001*0.002, cabezal: 0.0005, cable: 0.001, niquel: 0.360*0.0008, pasta: 0.001, tapon: 1,  oxido: 0.056/1000 },
        "10.00" : { arandela: 1, tubo: 0.001, disco:0.001, piedra: (1/220), hilo: 0.001*0.002, cabezal: 0.001, cable: 0.001, niquel: 0.360*0.001, pasta: 0.001, tapon: 1,  oxido: 0.07/1000 },
        "12.50" : { arandela: 1, tubo: 0.001, disco:0.0015, piedra: (1/330), hilo: 0.001*0.002, cabezal: 0.001, cable: 0.001, niquel: 0.360*0.003, pasta: 0.0015, tapon: 1,  oxido: 0.0875/1000 },
        "16.00" : { arandela: 1, tubo: 0.001, disco:0.0015, piedra: (1/310), hilo: 0.001*0.002, cabezal: 0.0015, cable: 0.001, niquel: 0.360*0.009, pasta: 0.002, tapon: 1,  oxido: 0.112/1000 },
        "20.00" : { arandela: 1, tubo: 0.001, disco:0.002, piedra: (1/330), hilo: 0.001*0.002, cabezal: 0.005, cable: 0.001, niquel: 0.360*0.020, pasta: 0.002, tapon: 1,  oxido: 0.14/1000 }
      },

      arandela_table : {
        "info" : "Por diametros en €",
        "6.6" : 0.06 ,
        "8.1" : 0.08 ,
        "10.1" : 0.077 ,
        "13.3" : 0.08,
        "16.1" : 0.09,
        "20.1" : 0.09
      },

      tubo_table : {
        "info" : "Tubo 1000mm acero inox 321 en €",
        "7.5" : 0.61*this.currency_table[1].value , //7.5x0.5x1000 $
        "9" : 0.71*this.currency_table[1].value , //9x0.5x1000 $
        "11.5" : 1.20*this.currency_table[1].value , //11.5x0.75x1000 $
        "14.5" : 1.75*this.currency_table[1].value , //14.5x0.75x1000 $
        "18" : 2.06*this.currency_table[1].value , //18x1x1000 $
        "22" : 2.52*this.currency_table[1].value //22x1x1000 $
      },

      disco_table : {//Un dicso de D21 Pesa 2gr y un disco de 7.5mm pesa 1 g
        "info" : "Disco base KG",
        "6.3" : 17.70 ,
        "7.7" : 14.63 ,
        "9.8" : 13.06 ,
        "12.3" : 8.85,
        "15.75" : 11.11 ,
        "18.7" : 10.91
      },

      piedra_table : {
        "info" : "Por unidd (diametros con 4 agujeros) en $",
        "4.2" : 0.17 ,  //x220
        "5" : 0.18 ,    //x220
        "6.5" : 0.233 , //x220
        "8.5" : 0.45 ,  //330
        "12" : 310 ,    //310
        "15" : 1.11     //330
      },

      hilo_table : {
        "info" : "Hilo Calefactor NiCro 80/20 KG",
        "0.07" : 47.57*this.currency_table[1].value , //$
        "0.1" : 72.33 , //€
        "0.15" : 60.88 , //€
        "0.25" : 58.32 , //€
        "0.3" : 40.10*this.currency_table[1].value , //$
        "0.4" : 38.70*this.currency_table[1].value , //$
        "0.5" : 35.00*this.currency_table[1].value  //$
      },

      cabezal_table : { //Una de D18 pesa 5 gramos, una de 6 no pesa, una 12 pesa 1gr
        "info" : "Cabezal ceramico KG $",
        /*"6.3x8" : 21.8,
        "6.3x15" : 22.15,
        "6.3x25" : 24.5,
        "7.7x8" : 21.8,
        "7.7x15" : 22.15 ,
        "7.7x25" : 24.5 ,
        "9.8x8" : 43 ,
        "9.8x15" : 20.20 ,
        "9.8x25" : 20.9 ,
        "12.3x10" : 43 ,
        "12.3x15" : 18.80 ,
        "12.3x25" : 18.80 ,
        "15,75x10" : 22.5 ,
        "15.75x15" : 18.40 ,
        "15.75x25" : 18.5*/

        "6.3" : 21.8*this.currency_table[1].value,
        "7.7" : 21.8*this.currency_table[1].value,
        "9.8" : 43*this.currency_table[1].value,
        "12.3" : 43*this.currency_table[1].value,
        "15,75" : 22.5*this.currency_table[1].value,
        "18.7" : 0

      },

      cable_table : {
        "info" : "Cable Fibra Vidrio",
        "0.22x1000" : 0.19 ,
        "0.5x1000" : 0.31 ,
        "0.75x1000" : 0.043 ,
        "1x1000" : 0.58 ,
        "1.5x1000" : 0.74 ,
        "2.5x1000" : 1.06
      },

      niquel_table : { //Una varilla de D1 = 1g, D1.3=3g , D1.5 = 9gr, D2=20gr
        "info" : "Niquel puro $",
        "0.7" : 28.40*this.currency_table[1].value ,
        "0.8" : 28.40*this.currency_table[1].value ,
        "1" : 35.50*this.currency_table[1].value ,
        "1.3" : 30.50*this.currency_table[1].value ,
        "1.5" : 30.20*this.currency_table[1].value ,
        "2" : 39.30
      },

      //Pasta referctaria. Una resistencia de D8 utiliza 1 gramos
      pasta_table : 16.94 * 1.15, //Libras esterlinas KG 1€=1.15L

      tapon_table : { //Un tapon de D22 pesa 2gr, 9=0, 7.5=0
        "info" : "Diamtros / unidad",
        "6.6" : 0.022 ,
        "8.1" : 0.024 ,
        "10.1" : 0.021 ,
        "13" : 0.025 ,
        "16.1" : 0.032 ,
        "20.1" : 0.064
      },

      oxido_table : { //Es la arena. Una resistencia de D9.52 L438 pesaba 128, luego pesa 160: 32/9.52/438=0.07
        "info" : "€/KG",
        "fina" : 5.84 ,
        "gorda" : 5.04
      },

      gastos_generales_fijos : {//Permanencen invariables
        "CUOTA ALQUILER PARQUE" : 200,      // € / MES
        "SUELDOS" : 235978 / 110 ,          // € / MES
        "TRIBUTOS" : 3370 ,                 // € / MES
        "SERVICIOS ASESORAMIENTO" : 12265 , // (ASESORIA, AMAZON, PATENTES, UL, PRL, RGPD) € / MES
        "TRANSPORTES":  40048 ,             // (UPS / MRW / ENVIALIA / SEUR)€ / MES
        "SEGUROS" : 3120 ,                  // € / MES
        "SERVICIOS BANCARIOS" : 1982 ,      // € / MES
        "PUBLICIDAD" : 7870 ,               // € / MES
        "SUMINISTROS" : 13743 ,             // (SERVICIOS / ALARMA / INTERNET / VIAJES / GASOLINA / TICKETS) € / MES
        "INTERESES BANCARIOS" : 5368        // € / MES
      },

      gastos_generales_variables : { //Aumentan de manera proporcional
        "mantenimiento" : 0,
      }
    }
  }

  constructor(private formBuilder: FormBuilder,) {
    this.newForm = formBuilder.group({
      'diameter' : ['', [Validators.required, Validators.pattern("^([0-9]*)\.?([0-9]*)?$"), Validators.minLength(1) ]],
      'longitude' : '',
      'lead' : ''
    });
  }

  ngOnInit() {

    this.current_year = ( this.prices[ this.current_year ] == undefined ? this.default_year :  this.current_year );
    this.current_prices = this.prices[ this.current_year ];

    this.objects2table( this.current_prices );    
  }

  submit(form: NgForm){

    if(form.dirty && form.valid){

      this.cartridgeheater.quantity = 1;
      this.cartridgeheater.diameter = form.value.diameter;
      this.cartridgeheater.longitude = form.value.longitude;
      this.cartridgeheater.lead = form.value.lead;

      this.cartridgeheater = this.gastos_calculator( this.price_calculator(this.cartridgeheater) );
    }
  }

  price_calculator(cartridgeheater){

    //const quantity = cartridgeheater.quantity ;
    var diameter = cartridgeheater.diameter;
    const longitude = cartridgeheater.longitude;
    const lead = cartridgeheater.lead;
    var total = 0;

    if( this.isNumber(diameter) &&  this.isNumber(longitude) && this.isNumber(lead)){

      var prices = this.current_prices;
      diameter = parseFloat(diameter).toFixed(2) ;
      const components = prices["componentes_table"][diameter];
      const componentes_quantity_table = prices["componentes_quantity_table"][diameter];
      

      //var regularexpresion = new RegExp("^([a-z0-9]{5,})$");


      if(typeof components !== 'undefined' ){

        total = cartridgeheater.componentes.arandela = prices[ "arandela_table" ][ components["arandela"] ] * componentes_quantity_table["arandela"];
        total += cartridgeheater.componentes.tubo = prices[ "tubo_table" ][ components["tubo"] ] * componentes_quantity_table["tubo"] * longitude;
        total += cartridgeheater.componentes.disco = prices[ "disco_table" ][ components["disco"] ] * componentes_quantity_table["disco"];
        total += cartridgeheater.componentes.piedra = prices[ "piedra_table" ][ components["piedra"] ] * componentes_quantity_table["piedra"] * longitude;
        total += cartridgeheater.componentes.hilo = prices[ "hilo_table" ][ components["hilo"] ] * componentes_quantity_table["hilo"] * longitude;
        total += cartridgeheater.componentes.cabezal = prices[ "cabezal_table" ][ components["cabezal"] ] * componentes_quantity_table["cabezal"];
        total += cartridgeheater.componentes.cable = prices[ "cable_table" ][ components["cable"]+"x1000" ] * componentes_quantity_table["cable"] * lead;
        total += cartridgeheater.componentes.niquel = prices[ "niquel_table" ][ components["niquel"] ] * componentes_quantity_table["niquel"];
        total += cartridgeheater.componentes.pasta = prices[ "pasta_table" ] * componentes_quantity_table["pasta"];
        total += cartridgeheater.componentes.tapon = prices[ "tapon_table" ][ components["tapon"] ] * componentes_quantity_table["tapon"];
        total += cartridgeheater.componentes.oxido = prices[ "oxido_table" ][ components["oxido"] ] * componentes_quantity_table["oxido"] * longitude* diameter;

        cartridgeheater.componentes.arandela  = this.isNumber(cartridgeheater.componentes.arandela) ? (cartridgeheater.componentes.arandela).toFixed(8) : 0 ; 
        cartridgeheater.componentes.tubo      = this.isNumber(cartridgeheater.componentes.tubo)     ? (cartridgeheater.componentes.tubo    ).toFixed(8) : 0 ; 
        cartridgeheater.componentes.disco     = this.isNumber(cartridgeheater.componentes.disco)    ? (cartridgeheater.componentes.disco   ).toFixed(8) : 0 ;  
        cartridgeheater.componentes.piedra    = this.isNumber(cartridgeheater.componentes.piedra)   ? (cartridgeheater.componentes.piedra  ).toFixed(8) : 0 ; 
        cartridgeheater.componentes.hilo      = this.isNumber(cartridgeheater.componentes.hilo)     ? (cartridgeheater.componentes.hilo    ).toFixed(8) : 0 ; 
        cartridgeheater.componentes.cabezal   = this.isNumber(cartridgeheater.componentes.cabezal)  ? (cartridgeheater.componentes.cabezal ).toFixed(8) : 0 ; 
        cartridgeheater.componentes.cable     = this.isNumber(cartridgeheater.componentes.cable)    ? (cartridgeheater.componentes.cable   ).toFixed(8) : 0 ;  
        cartridgeheater.componentes.niquel    = this.isNumber(cartridgeheater.componentes.niquel)   ? (cartridgeheater.componentes.niquel  ).toFixed(8) : 0 ; 
        cartridgeheater.componentes.pasta     = this.isNumber(cartridgeheater.componentes.pasta)    ? (cartridgeheater.componentes.pasta   ).toFixed(8) : 0 ;  
        cartridgeheater.componentes.tapon     = this.isNumber(cartridgeheater.componentes.tapon)    ? (cartridgeheater.componentes.tapon   ).toFixed(8) : 0 ;  
        cartridgeheater.componentes.oxido     = this.isNumber(cartridgeheater.componentes.oxido)    ? (cartridgeheater.componentes.oxido   ).toFixed(8) : 0 ;  

        total *= this.currency.value;

        cartridgeheater.price = +(total).toFixed(2); //Redondeamos a 2 decimales y de paso nos aseguramos que JS no haga cosas raras.

      }else this.reset_calculator();
    }

    return cartridgeheater;
  }

  gastos_calculator(cartridgeheater){
    //SELECT SUM(cantidad) FROM pedido WHERE  fecha_finalizacion >= "2023-10-01" AND  fecha_finalizacion <= "2023-10-31" = Resistencias hechas en Octubre 19423
    var total = 0;
    const gastos_fijos = this.current_prices[ "gastos_generales_fijos" ];
    const gastos_variables = this.current_prices[ "gastos_generales_variables" ];

    const cartridge_manufature = 19423;

    for (const [key, value] of Object.entries(gastos_fijos)) {
      total += parseFloat(value+'');
    }

    for (const [key, value] of Object.entries(gastos_variables)) {
      total += parseFloat(value+'');
    }

    total = total/cartridge_manufature;

    cartridgeheater.gastos = total.toFixed(2);

    return cartridgeheater;

  }

  objects2table( objects ){

    var tbody = document.getElementById('innerhtmlDiv');

    for (const [key, value] of Object.entries(objects)) {
      //if(value["info"] != undefined){
        tbody.innerHTML += `<div class="row">${key} `+value["info"] +'</div>';
        delete value["info"];
      //} 
      tbody.innerHTML += this.object2table(value) + "<br>";
    }

  }

  object2table(object){
    //var tr = "<table><tbody>";
    var tr = '';

    for (const [key, value] of Object.entries(object)) {

      //tr += "<tr><td>" + key + "</td><td>" + ( this.isObject(value) ? JSON.stringify(value) : value )+ "</td></tr>";
      tr += '<div class="row"><div class="col-2">' + key + '</div><div class="col-10">' + ( this.isObject(value) ? JSON.stringify(value) : value )+ '</div></div>';
    }

    //tr += "</tbody></table>";
    return tr;
  }

  inputsChanges(form: NgForm){

    this.submit( form );
  }

  reset_calculator(){

    this.cartridgeheater = new cartridgeheater;
    this.price_calculator( this.cartridgeheater );
  }

  isObject(value) {
    return (
      typeof value === 'object' &&
      value !== null &&
      !Array.isArray(value)
    );
  }

  isNumber(value) {
    if (typeof value == "string"  )  value = parseFloat(value);

    return (typeof value === 'number' && !isNaN(value));
  }

}

//cartridgeheater object
function cartridgeheater(){

  this.quantity = 0 ;
  this.diameter = 0 ;
  this.longitude = 0;
  this.lead = 0;
  this.componentes = {
      arandela : 0 ,
      tubo : 0 ,
      disco : 0 ,
      piedra : 0 ,
      hilo : 0 ,
      cabezal : 0 ,
      cable : 0 ,
      niquel : 0 ,
      pasta : 0 ,
      tapon : 0 ,
      oxido : 0
  };
  this.price = 0;
  this.gastos = 0;
}
