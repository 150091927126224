import { Component, OnInit } from '@angular/core';
import { CustomersService } from '../../services/customers_final.service';
import { FilterPipe } from 'ngx-filter-pipe';

import { ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators,NgForm } from '@angular/forms';

import { Pipe, PipeTransform } from "@angular/core";

@Component({
  selector: 'app-all',
  templateUrl: './allfinalcustomers.component.html',
  styleUrls: ['./allfinalcustomers.component.less']
})
export class AllFinalCustomersComponent implements OnInit {

  private sub: any;

  newForm: FormGroup;

  alls : any = [];
  show:boolean = false;
  response;

  index;
  id;

  key: string = 'fecha';
  reverse: boolean = false;
  sort(key){
    this.key = key;
    this.reverse = !this.reverse;
  }

  searchText: string = "";
  filterMetadata = { count: 0 };

  constructor(private customerservice: CustomersService, private filter: FilterPipe, private route: ActivatedRoute, private formBuilder: FormBuilder) {  
    this.newForm = formBuilder.group({
      'update': ['', Validators.maxLength(79)],
      'state': [''],
    });
  }

  ngOnInit() {

    //this.sub = this.route.params.subscribe(params => {

      // In a real app: dispatch action to load the details here.

      this.get_all();
    //});
  }

  get_all(){
    this.customerservice.all_customers().subscribe(

      data => {
        this.alls= data;

        for (let i in this.alls){
           this.alls[i].index = i;           
        }
      }
    );    
  }

  show_message(){

    this.show = !this.show;

    setTimeout(() => {
      this.hides_message();
    }, 2000);
  }

  hides_message(){
    if(this.show=false){
      this.show;
    }
  }
  
}
