import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { RemindersService } from '../../services/reminders.service';
import { FormBuilder, FormGroup } from '@angular/forms';

//import { ModalViewCustomerComponent } from '../../../shared/components/modal_edit_customer/modal_edit_customer.component';
import { ModalCreateRemindComponent } from '../../../shared/components/modal_edit_remind/modal_create_remind.component';

import { GlobalConstants } from 'src/app/shared/constants/global-constants';

@Component({
  selector: 'app-allreminds',
  templateUrl: './all_reminds.component.html',
  styleUrls: ['./all_reminds.component.less']
})
export class AllRemindsComponent implements OnInit {

  reminds: any = [];;

  idcomercial: number = 0;
  nombre_comercial: string;
  user_type:string = GlobalConstants.user_type;
  user_id:string = GlobalConstants.user_id;

  key: string = 'date_remind';
  reverse: boolean = true;
  sort(key){
    this.key = key;
    this.reverse = !this.reverse;
  }
  p: number = 1;
  response;

  show:boolean;

  newForm: FormGroup;
  FormTitleString: string;
  input_form_disabled: boolean;
  date_updated;
  remind: any = {"idempresa":0};
  email;

  createRemindForm: FormGroup;

  searchText: String = '';
  filterMetadata = { count: 0 };

  constructor(private remindersService: RemindersService,private route:ActivatedRoute, private formBuilder: FormBuilder, /*private viewcustomerService:ModalViewCustomerComponent,*/ private createnotificationService:ModalCreateRemindComponent) { 
    //Conectamos con los formularios de otros componentes
    /*this.newForm = viewcustomerService.newForm;
    this.FormTitleString = viewcustomerService.FormTitleString;
    this.input_form_disabled = viewcustomerService.input_form_disabled;*/

    this.createRemindForm = createnotificationService.createRemindForm;
  }

  ngOnInit() {
    this.idcomercial=this.route.snapshot.params.idcomercial
    this.nombre_comercial=this.route.snapshot.params.nombre_comercial;

    this.get_reminds();
  }

  get_reminds(){
    this.remindersService.get_reminds(this.idcomercial).subscribe(
      data =>{
        this.init_table_reminds(data);
      }
    );
  }

  init_table_reminds(data){

    if(data == "logout") window.location.href = '/#/login';
    else{
      this.reminds= data;
    }
  }
  
  show_modal_view(item){

    this.remind = item;

    this.FormTitleString = "View ";
    this.date_updated =  item.date_updated;
    this.email = item.email;

    this.input_form_disabled = true;
    this.createnotificationService.show_modal(item);
  }

  show_modal_edit(item){

    this.remind = item;

    this.FormTitleString = "Edit ";
    this.date_updated =  item.date_updated;
    //this.fecha_edit = item.fecha_edit;
    this.email = item.email;

    this.input_form_disabled = null;
    this.createnotificationService.show_modal(item);
  }

  download_ics(item){

    const ics_obj = [
      'data:text/calendar;charset=utf8,',
      'BEGIN:VCALENDAR',
      'VERSION:2.0',
      'BEGIN:VEVENT',
      'SUMMARY:' + item.nombre,
      'DESCRIPTION:' + item.recordatorio,
      'DTSTART:' + new Date(item.date_remind).toISOString(),
      'DTEND:' + new Date(item.date_remind).toISOString(),
      'LOCATION:' + "CRM Maxiwatt",
      'TRANSP:TRANSPARENT',
      'END:VEVENT',
      'END:VCALENDAR',
      //'UID:' + this.id,
      //'DTSTAMP:' + this.createdTime,
      'PRODID:website-1.0'
    ].join('\n');

    //console.log(ics_obj);

    window.open( ics_obj );
  }
}
