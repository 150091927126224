import { Pipe, PipeTransform } from "@angular/core";

/*
You can use pipes to link operators together. 
Pipes let you combine multiple functions into a single function.
The pipe() function takes as its arguments the functions you want to combine, and returns a new function that, when executed, runs the composed functions in sequence.

Para que el filtro y la paginación funcione perfectamente debe estar antes searchFilter que paginate
*/
@Pipe({
  name: "searchFilter"
})
export class SearchFilterPipe implements PipeTransform {
  transform(value: any, args?: any, filterMetadata? : any): any {
    if (!value || value=='') return null;
    if (!args) return value;

    args = args.toLowerCase();
    debugger;

    let filteredItems = value.filter(function(item) { 

      let item_copy = JSON.parse( JSON.stringify(item) ); //Copia por Valor para no modificar el original

      if( filterMetadata!=null && filterMetadata.filters != null ){ //Solo filtramos por los indices que nos interesan Opcional

        for (const key in item_copy) {
          if( !filterMetadata.filters.includes(key) ){
            delete item_copy[key];
          }
        };

      }

      return JSON.stringify(item_copy)
        .toLowerCase()
        .includes(args);
    });

    if(filterMetadata!=null && filterMetadata.count != null) filterMetadata.count = filteredItems.length; //Parametro opcional: Devuelve el contador de resultado

    return filteredItems;

  }
}