import { Component, OnInit, Injectable, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators, NgForm, FormGroupDirective, FormControl  } from '@angular/forms';

import { RemindersService } from '../../../core/services/reminders.service';
import { GlobalConstants } from '../../constants/global-constants';
import { GlobalFunctions } from '../../constants/global-functions';

@Component({
  selector: 'app-modalcreateremind',
  templateUrl: './modal_create_remind.component.html',
  styleUrls: ['./modal_create_remind.component.less']
})
@Injectable({
  providedIn: 'root'
})
export class ModalCreateRemindComponent implements OnInit {

  //@Input() fecha_create:string;
  @Input() date_updated:string;
  @Input() createRemindForm: FormGroup;
  @Input() remind = {"idempresa":0,"nombre":"","idcomercial":0,"fecha_recor":"","hora":"","recordatorio":"","destacado":0};
  @Input() input_form_disabled; //null or true
  @Input() email; //comercial email

  destacado_array_str = GlobalConstants.destacado_array_str;
  comercials_array_str = GlobalConstants.comercials_array_str;
  
  alertshow:boolean = false;
  
  response;

  constructor(private remindService: RemindersService, private formBuilder: FormBuilder) {
    this.createRemindForm = this.formBuilder.group({
      //'nombre' : '',
      'idcomercial' : new FormControl({value: '', disabled: true }),
      'date_remind' :  new FormControl({value: '' }, Validators.required),
      //'hora' : new FormControl({value: '' }, Validators.required),
      'recordatorio' : new FormControl( '' , [Validators.required,  Validators.maxLength(1000)] ),
      'destacado' : new FormControl({value: '', disabled: true })
    });
  }

  ngOnInit() {
  }

  alert_show(){
    this.alertshow = true;

    setTimeout(() => {
      this.alert_hide();
    }, 2000);
  }

  alert_hide(){
    this.alertshow = false;
  }

  show_modal(item:any){

    this.createRemindForm.patchValue(item);

    this.createRemindForm.patchValue({
      date_remind: GlobalFunctions.datetime_to_date(item.date_remind)
    });
  }

  submit(form: NgForm){

    if(form.dirty && form.valid){

      form.value.idempresa = this.remind.idempresa;
      form.value.idcomercial = this.remind.idcomercial;

      this.remindService.new_remind(
        form.value
      ).subscribe(
        result => {
          if(result['resultado'] == 'OK') {
          this.response=result['mensaje'];
          
          this.alert_show();
        } 
      
        //Here what to do after...
        this.createRemindForm.patchValue({
          recordatorio : '',
          date_remind : '',
          //hora : ''
        });       
        //this.send_mail(fecha_recor,hora,comentario);
      });
    }
  }
}
