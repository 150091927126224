import { Component } from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./../styles.less']
})

export class AppComponent {
  
  constructor(
    

  ) { 
    
  }

  ngOnInit() {
  }

  ocultar(){
    var list = document.getElementsByClassName("collapse")[0];
    list.classList.remove("show");
  //console.log(list);
  }
  
}
