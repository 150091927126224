import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { GlobalConstants } from 'src/app/shared/constants/global-constants';

@Injectable({
  providedIn: 'root'
})
export class AnswerService{

  config;

  id_user=localStorage.getItem('id');

  constructor(public http: HttpClient) {
    this.config = {headers : {
        "Content-Type": "application/json; charset = utf-8;"
      }
    };
  }

  get_answers(){
    return this.http.get(`${GlobalConstants.apiURL}get_answers.php`);
  }

  set_answer(answer){
    var post = this.http.post(`${GlobalConstants.apiURL}set_answer.php`,JSON.stringify({answer},this.config));
    return post
  }
}
