import { Injectable } from '@angular/core';
import { HttpClient,HttpHeaders  } from '@angular/common/http';
import { Observable } from 'rxjs';

import { GlobalConstants } from 'src/app/shared/constants/global-constants';

@Injectable({
  providedIn: 'root'
})

export class RemindersService {

  config;
  //fecha = new Date().toLocaleString('latin');

  constructor(public http: HttpClient) {
    
    this.config = {headers : {
      "Content-Type": "application/json; charset = utf-8;"
    }}; 
  }

  new_remind(remind){
      var post = this.http.post(`${GlobalConstants.apiURL}new_remind.php`, JSON.stringify({remind},this.config));
      return post;
  }

  set_remind(remind){
    var post=this.http.post(`${GlobalConstants.apiURL}set_remind.php`,JSON.stringify({remind},this.config));
    return post
  }

  delete_remind(remind){
    var post=this.http.post(`${GlobalConstants.apiURL}del_remind.php`,JSON.stringify({remind},this.config));
    return post
  }

  get_reminds(idcomercial){
    var post = this.http.post(`${GlobalConstants.apiURL}get_reminds.php`,JSON.stringify({idcomercial},this.config));
    return post
  }

  get_remind_by_customer(idcompany){
    var post = this.http.post(`${GlobalConstants.apiURL}get_remind_by_customer.php`,JSON.stringify({idcompany},this.config));
    return post
  }


}
