import { Component, OnInit } from '@angular/core';
import { SeguimientoService } from '../../services/seguimiento.service';
import { FormBuilder, FormGroup, Validators,NgForm } from '@angular/forms';

import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';

import { GlobalConstants } from '../../../shared/constants/global-constants';

@Component({
  selector: 'app-new',
  templateUrl: './newseguimiento.component.html',
  styleUrls: ['./newseguimiento.component.less']
})
export class NewSeguimientoComponent implements OnInit {

  newForm: FormGroup;

  id_empresa: number;
  empresa;
  type;
  private sub: any;

  id_user;

  response;
  show;

  offer_state_array_str = GlobalConstants.offer_state_array_str;

  offers = [];

  show_table = false;

  currentYear = new Date().getFullYear().toString().substr(-2);

  constructor(private seguimientoservice: SeguimientoService, private formBuilder: FormBuilder, private route: ActivatedRoute, private router: Router) {

    this.newForm = formBuilder.group({
      'oferta': ['', [Validators.required, Validators.maxLength(100) ]],
      'state': ['', Validators.maxLength(79)],
      'observacion': ['', Validators.maxLength(1000)],
      'units': ['', [Validators.required, Validators.pattern("^[0-9]*$"), Validators.minLength(1) ]],
    });
  }

  ngOnInit() {
    this.id_user = localStorage.getItem('id');
        
    this.sub = this.route.params.subscribe(params => {
      this.id_empresa = +params['id_empresa']; // (+) converts string 'id' to a number
      this.empresa = params['empresa'];
      this.type = +params['type'];
      // In a real app: dispatch action to load the details here.
   });
  }

  submit(form: NgForm){

    form.value.id_empresa = this.id_empresa;
    form.value.id_user = this.id_user;
    form.value.type = this.type;

    if(form.dirty && form.valid){

      this.seguimientoservice.newseguimiento(form.value).subscribe(
        result => {
          if(result['resultado'] == 'OK') {
            this.response=result['mensaje'];
            this.show = !this.show;

            this.show_table = true;

            this.offers.push({
              //id_empresa : this.id_empresa,
              empresa: this.empresa,
              oferta: form.value.oferta,
              comercial: form.value.comercial,
              units: form.value.units,
              observacion: form.value.observacion,
              state_str: (form.value.state != '' ? this.offer_state_array_str[ form.value.state ].value : '')
            });

            this.newForm.patchValue({
              units: ''
            });
        }
      });
      //f.resetForm();
    }

  }

  return(){
    this.router.navigate(['/allseguimientos', this.id_empresa, this.empresa , this.type]);
  }
}
